.page{
  &.about-us-page{
    padding-bottom: 0;
    overflow: hidden;

    .page__main-block{

      .page__main--content{
        min-height: 300px;

        @include break-max($mob){
          flex-direction: column-reverse;
        }

        .page__main-block-left{
          text-align: center;

          @include break-min($mob + 1){
            width: 420px;
            min-height: 200px;
          }

          @include break-min(1199px){
            margin-bottom: -80px;
          }

          img{
            display: inline-block;
          }
        }

        .page__main-block-right{
          width: calc(100% - 450px);
          background: transparent;

          @include break-max($mob){
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 25px;
          }

          .section-header{
            margin-bottom: 20px;

            @include break-max($mob){
              margin-bottom: 5px;
            }

            .ui-subtitle{
              line-height: 1.4;
            }
          }

          .ui-small{
            &:nth-child(1){
              margin-bottom: 5px;
            }

            margin-bottom: 10px;
            line-height: 1.4;
          }
        }
      }
    }

    .key-benefits-section{
      margin: 25px 0;
      text-align: center;

      @include break-min(1199px){
        text-align: right;
        margin-top: -40px;
        margin-bottom: 70px;
      }


      .key-benefits--list{
        max-width: 800px;
        margin: 0 auto;

        @include break-min(1199px){
          width: calc(100% - 390px);
        }
      }
    }

    .section__advantage{
      padding-top: 40px;
      margin-top: 70px;
      border-top: 1px solid $border-color;
    }

    .about-us--additional-info{
      .about-us--info-container{
        display: flex;
        @include break-max($mob){
          flex-wrap: wrap;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          justify-content: center;
          flex-direction: column-reverse;
        }

        @include break-max(1199px){
          padding-top: 35px;
        }

        .about-us--info-container-item{
          width: 100%;

          .ui-small{
            margin-bottom: 5px;

            &:last-child{
              margin-bottom: 15px;
            }
          }

          .dr-link{
            text-decoration: underline;
          }

          &.info-container-item-logo{
            width: 250px;
            min-width: 250px;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .link{
              display: inline-block;
              width: 100%;
              max-width: 220px;
              height: 40px;
            }

            .simplybook--logo-image{
              max-width: 250px;
            }

            @include break-max($mob){
              width: auto;
              margin-bottom: 30px;
              padding-left: 30px;
              padding-right: 30px;
            }

            .ui-cursive{
              display: inline-block;
              letter-spacing: 1px;
              position: relative;
              padding: 20px 15px 0 15px;

              &:before{
                content: '';
                display: block;
                position: absolute;
                left: -35px;
                top: -25px;
                height: 60px;
                width: 30px;
                background: url("../../images/ics/arrow-cursive.svg") no-repeat;
                background-size: 26px;
                transform: scale(1, -1);
              }
            }
          }
        }
      }
    }

    .our-team-section{
      text-align: center;
      position: relative;
      width: 100%;
      overflow: hidden;

      .our-team--description{
        position: relative;
        padding: 40px 0 10px 0;

        .our-team--description-content{
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;

          .ui-section-title{
            margin-bottom: 5px;
          }
        }

        .shape{
          position: absolute;
          width: 100%;
          top: calc(100% - 2px);
          left: 0;
        }
      }

      .video-cover {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #000;
        overflow: hidden;

        video {
          width: 100%;
          height: 100%;

          @include break-max($mob){
            min-height: 210px;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
      }

      .img-responsive{
        width: 100%;
      }
    }

    .easy-to-use__body{
      padding: 0 25px;

      @include break-max($mob){
        justify-content: center;
        align-items: center;
      }

      @include break-max($tab){
        flex-direction: column;
      }

      .easy-to-use__content{
        padding: 65px 0 40px 0;

        @include break-max($mob){
          text-align: center;
        }

        @include break-max($tab){
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 25px;

          .easy-to-use__content-item{
            width: 48%;

            &.easy-to-use__content-item-bar{
              text-align: center;
            }

            @include break-max($mob){
              width: 100%;
            }
          }
        }

        .ui-hero-title.ui-section-title{
          font-weight: 900;
          margin-bottom: 5px;
          font-size: 60px;
          line-height: 1;

          @include break-max($mob){
            font-size: 45px;
          }
        }

        .txt{
          color: $gray-font-color;
          margin-bottom: 10px;
        }

        .rating-block{
          margin-bottom: 25px;
          @include break-max($tab){
            flex-wrap: wrap;
          }

          li{
            @include break-max($tab){
              width: calc(50% - 15px);
              margin: 10px 0;
              text-align: center;
              flex: 50%;
            }
          }
        }
      }

      .easy-to-use__image{
        @include break-max($tab){
          width: calc(100% + 80px);
        }
      }
    }

    .cta-block{
      &.cta-block-v2{
        &:after{
          background: $gray-background-color;

          @include break-max($mob){
            background: #141414;
          }
        }
      }
    }

    .reviews-carousel-section {
      padding-top: 20px;
    }

    .reviews-carousel-wrapper{
      padding-bottom: 65px;
    }

    .section-contact-us{
      background: $gray-background-color;
      padding: 15px 0 10px 0;
      .contact-us--body{
        max-width: 860px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .contact-us--item{
          width: 100%;
          margin-bottom: 30px;

          @include break-min($mob + 1){
            width: 50%;
          }

          &.contact-us--item-map{
            text-align: center;
            height: 400px;
            position: relative;

            .mapboxgl-canvas-container{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;

              .mapboxgl-canvas{
                top: 0;
                left: 0;
              }

              .marker {
                background-image: url("../../images/ics/marker-dark.svg");
              }
            }

            @include break-min($mob + 1){
              width: 400px;
              min-width: 400px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              -khtml-border-radius: 50%;
              -webkit-mask-image: -webkit-radial-gradient(white, black);
              overflow: hidden;
            }
          }

          &.contact-us--item-data{
            width: 100%;
            padding-left: 0;
            text-align: center;

            @include break-min($mob + 1){
              text-align: left;
              padding-left: 100px;
              width: calc(100% - 400px);
            }

            .ui-section-title{
              margin-bottom: 20px;
            }

            .ui-small{
              margin-bottom: 10px;
              display: flex;
              justify-content:flex-start;
              position: relative;
              text-align: left;

              &.email{
                &:before {
                  display: flex;
                  content: '';
                  margin-left: -30px;
                  width: 16px;
                  height: 16px;
                  margin-right: 15px;
                  background: url('../../images/ics/mail.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @include break-max($mob){
                    margin-left: 0;
                  }
                }
              }
              &.location{
                &:before {
                  display: flex;
                  content: '';
                  margin-left: -30px;
                  width: 16px;
                  height: 16px;
                  margin-right: 15px;
                  background: url('../../images/ics/map-marker.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @include break-max($mob){
                    margin-left: 0;
                  }
                }
              }
              &.dock{
                &:before {
                  display: flex;
                  content: '';
                  margin-left: -30px;
                  width: 16px;
                  height: 16px;
                  margin-right: 15px;
                  background: url('../../images/ics/categories-folder.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @include break-max($mob){
                    margin-left: 0;
                  }
                }
              }
            }

            .social{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 25px -10px 15px -10px;

              .social-item{
                margin: 0 10px;

                &.fb:before {
                  display: flex;
                  content: '';
                  width: 30px;
                  height: 30px;
                  background: url('../../images/ics/social/facebook.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @media (max-width: $mob) {
                    width: 35px;
                    height: 35px;
                  }
                }

                &.tw:before {
                  display: flex;
                  content: '';
                  width: 30px;
                  height: 30px;
                  background: url('../../images/ics/social/twitter.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @media (max-width: $mob) {
                    width: 35px;
                    height: 35px;
                  }
                }

                &.yt:before {
                  display: flex;
                  content: '';
                  width: 28px;
                  height: 28px;
                  background: url('../../images/ics/social/youtube.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;

                  @media (max-width: $mob) {
                    width: 33px;
                    height: 33px;
                  }
                }
              }
            }
          }

          //&.contact-us--item-form{
          //  width: 100%;
          //
          //  .ui-section-title{
          //    margin-bottom: 5px;
          //  }
          //
          //  .wrap-contact-us-from{
          //    max-width: 420px;
          //    margin: 30px auto 0 auto;
          //    text-align: center;
          //
          //    .one-line{
          //      margin-bottom: 8px;
          //    }
          //
          //    .dr-input{
          //      font-weight: 300;
          //      width: 100%;
          //      resize: none;
          //      @include transition;
          //
          //      &:focus{
          //        background: #fff;
          //        box-shadow: 0px 0px 6.93px 0.07px rgba(0, 0, 0, 0.18);
          //      }
          //
          //      &.dr-textarea{
          //        padding: 20px;
          //        height: 160px;
          //      }
          //    }
          //
          //    .dr-btn{
          //      margin-top: 10px;
          //
          //      &:after{
          //        background: url("../../images/ics/check-white.svg") no-repeat center;
          //      }
          //    }
          //  }
          //}
        }
      }
    }
  }
}