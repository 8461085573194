.dr-checkbox {
  display: block;
  width: 12px;
  height: 12px;
  border: 4px solid transparent;
  background: $secondary-color;
  @include transition;

  &:checked {
    border-color: $base-color !important;
  }

  @include break-max($tab){
    width: 16px;
    height: 16px;
  }
}