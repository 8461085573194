.confirm-location-dialog{
  position: absolute;
  width: 270px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 36px;
  z-index: 1040;
  background: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  //&:before {
  //  position: absolute;
  //  content: '';
  //  top: -2px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  width: 4px;
  //  height: 4px;
  //  border-radius: 50px;
  //  background: $black-color;
  //  z-index: 1050;
  //}

  @include break-max($tab) {
    top: 40px;
    left: auto;
    right: 0;
    transform: translateX(0%);

    &:before {

      left: auto;
      transform: translateX(0%);
      right: 11px;
    }
  }

  .confirm-location-dialog__body{
    display: flex;
    flex-wrap: wrap;

    .confirm-location-dialog__header{
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0 20px;
      font-weight: 600;
      font-size: 14px;

      @include break-max($mob){
        font-size: 16px;
      }
    }

    .btn-bar{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dr-btn{

      &.no-confirm-location-btn{
        color: $gray-font-color;
        font-weight: 100;
        font-size: 14px;

        &:hover{
          color: $black-color;
        }
      }

      &.dr-btn--icon-right{
        &:after{
          background: url("../../images/ics/check-white.svg") no-repeat center;
        }
      }
    }
  }
}