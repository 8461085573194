.page{
  &.all-categories-page{
    padding-bottom: 20px;

    .section__top-activities{
      margin-bottom: 0;
      background: transparent;
      padding-top: 10px;

      .top-activities--header{
        max-width: 600px;
        margin: 0 auto 30px auto;

        .ui-subtitle{
          margin-bottom: 15px;

          @include break-max($mob){
            margin-bottom: 0;
          }
        }
      }

      .top-activities-block{
        @include break-max($tab){
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        @include break-max($mob){
          max-width: 396px;
        }

        ul{
          @include break-max($tab){
            justify-content: center;
          }


          .top-activities__item{
            width: calc(20% - 10px);

            @include break-max($tab){
              width: calc(33.33% - 10px);
            }

            @include break-max($mob){
              width: calc(50% - 10px);
            }

            @include break-max(600px){
              width: calc(50% - 10px);
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 5px;
            }

            .top-activities__text{
              @include break-max($tab){
                display: none;
              }
            }
          }
        }
      }
    }

    .key-benefits-section{
      background: $secondary-color;

      .key-benefits--list{
        .key-benefits--item {
          @include break-min($mob + 1){
            justify-content: center;
            text-align: center;

            .key-benefits--name{
              text-align: left;
            }
          }
        }
      }
    }

    .categories-view--list{
      .categories-item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px 0;
        border-bottom: 2px solid $border-color;

        @include break-min(600px){
          justify-content: flex-start;
          flex-wrap: nowrap;
        }

        @include break-max($mob){
          padding: 35px 0;
        }

        .categories-item--img{
          height: 236px;
          width: 260px;
          min-width: 260px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 20px;

          @include break-min($mob + 1){
            align-items: center;
            height: 320px;
            width: 300px;
            min-width: 300px;
            margin-bottom: 0;
          }

          picture{
            max-width: 222px;
            max-height: 222px;
            position: relative;

            &:before{
              content: '';
              position: absolute;
              bottom: -15px;
              left: -15px;
              display: block;
              height: 100px;
              width: 100px;
              border-radius: 50%;
              background: $secondary-color;
            }

            img{
              width: 100%;
              height: auto;
              position: relative;
              z-index: 2;
            }
          }
        }

        .categories-item--body{
          padding: 0 30px;

          @include break-max($mob){
            margin-bottom: 10px;
          }

          @include break-max(600px){
            padding: 0 15px;
          }

          .categories-item--title{
            font-size: 24px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 5px;
          }

          .categories-item--sort-descr{
            font-size: 16px;
            margin-bottom: 25px;
            line-height: 1.4;
          }

          .categories-item--sub-cat-list{
            margin-bottom: 30px;

            .sub-category-list{
              margin: 0;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-wrap: wrap;

              @include break-min($mob + 1){
                margin: 0 -10px;
              }

              .sub-category--list-item{
                width: 100%;
                margin: 5px 0;

                @include break-min($mob + 1){
                  width: calc(50% - 10px);
                  margin: 2px 5px;
                }

                @include break-min($tab){
                  width: calc(33.33% - 10px);
                }

                .sub-category--link{
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  min-height: 22px;
                  font-size: 16px;
                  color: $gray-font-color;
                  @include transition;

                  &:before{
                    content: '';
                    display: block;
                    height: 4px;
                    width: 4px;
                    border-radius: 50%;
                    margin-right: 15px;
                    background: #818181;
                    @include transition;
                  }

                  &:hover{
                    color: $black-color;

                    &:before{
                      background: $black-color;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-child(even){
          flex-direction: row-reverse;

          .categories-item--img{
            picture{
              &:before{
                left: auto;
                right: -15px;
                background: #000;
              }
            }
          }
        }

        &:last-child{
          border-bottom: none;
        }
      }
    }

    .cta-block-v2{
      @include break-max($mob){
        padding-bottom: 10px;
      }
    }

  }
}