.modal{
  &.modal-company{

      .company-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 20px 60px 20px 25px;
        background: $gray-background-color;
        min-height: 40px;

        .company-modal__name {
          font-size: 16px;
          font-weight: 500;
          margin-right: 10px;

          @media (max-width: $mob) {
            display: none !important;
          }
        }

        .company__location {
          max-width: 50%;
          overflow: hidden;
          padding: 0;
          border: none;
          margin-left: auto;

          &-address {
            max-width: 100%;
            display: block !important;
            margin: 0;
          }

          @media (max-width: $mob) {
            display: none !important;
          }
        }
      }

      .modal-company__header-item {
        position: relative;
        display: flex;
        flex-direction: column;

        //&.icon {
        //  flex-direction: row;
        //  align-items: center;
        //  justify-content: flex-start;
        //  margin-top: 30px;
        //  padding: 0 20px;
        //
        //  @media (max-width: $mob) {
        //    margin: 0;
        //    padding-top: 30px;
        //  }
        //
        //  .modal-company__item-image {
        //    width: 50px;
        //    min-width: 50px;
        //    height: 50px;
        //    border-radius: 50px;
        //    overflow: hidden;
        //    background: $gray-background-color;
        //  }
        //
        //  .modal-company__header-title {
        //    margin-top: 0;
        //    padding-left: 20px;
        //  }
        //}


        .modal-company__item-image {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 0;
          margin: 0;

          //&.icon {
          //  overflow: visible;
          //
          //  img {
          //    top: 50%;
          //    left: 50%;
          //    position: absolute;
          //    transform: translate(-50%, -50%);
          //    width: 40px;
          //    height: 40px;
          //  }
          //}

          img {
            height: 100%;
            width: 100%;
            max-height: 600px;
            object-fit: contain;

            @media (max-width: $mob) {
              max-height: 250px;
            }
          }
        }

        .modal-company__header-title {
          font-size: 18px;
          font-weight: 600;
          color: $black-color;
          padding: 0 25px;
          margin-top: 25px;
          margin-bottom: 0;
          white-space: normal;
          word-break: break-word;
        }
      }

      .modal-company__body {
        padding: 5px 25px 20px;
        color: $gray-font-color;
        font-size: 14px;
        font-weight: 300;

        .provider__category{
          margin-top: 15px;
        }
      }

      .modal-company__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 35px;
        border-top: 1px solid $border-color;

        @media (max-width: $mob) {
          flex-wrap: wrap;
          padding-left: 0;
        }

        .service__price, .service__duration {
          width: 15%;
          white-space: nowrap;
          font-size: 12px;
          color: $gray-font-color;
          font-weight: normal;

          @media (max-width: $mob) {
            font-size: 14px;
            width: 50%;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        .service__duration {
          margin-right: auto;

          @media (max-width: $mob) {
            padding-left: 20px;
          }
        }

        .service__price {
          margin-right: 25px;
          @media (max-width: $mob) {
            margin: 0;
            padding-right: 20px;
            text-align: right;
          }

          &-value {
            color: $black-color;
            font-weight: 600;
            font-size: 14px;
            margin-left: 5px;
            @media (max-width: $mob) {
              font-size: 16px;
            }
          }
        }

        .service__button {
          height: 38px;

          &:after {
            background-image: url('../../../images/ics/arrow-right.svg');
          }

          @media (max-width: $mob) {
            width: 100%;
            justify-content: center;
          }
        }
      }

  }
  &.show-map-modal{
    .company-modal__header .company__location {
      max-width: 100%;
      margin-left: 0;
    }
  }
  &.service-modal{
    .modal-company__body{
      .company__attributes-list{
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        color: $base-color;
        padding: 15px 0 0 0;

        .company__attributes-cat{
          color: $gray-font-color;
        }
      }
    }
  }
}