.section__advantage{
  padding: 40px 0;

  .advantage-block{
    @include break-max($tab){
      padding: 0 20px;
    }

    @include break-max($mob){
      padding: 0 10px;
    }

    ul{
      margin: 0 -10px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include break-max($mob){
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      li{
        text-align: center;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.2s ease-in;

        @include break-max($mob){
          width: calc(33.33% - 20px);
          min-height: 80px;
          padding: 10px 0;
        }

        span{
          position: relative;
        }

        .icon{
          svg{
            height: 36px;
          }
        }

        //&:before{
        //  content: '';
        //  display: block;
        //  opacity: 0;
        //  position: absolute;
        //  top: 0;
        //  bottom: 0;
        //  left: 0;
        //  right: 0;
        //  width: 0px;
        //  height: 0px;
        //  min-width: 0px;
        //  min-height: 0px;
        //  border-radius: 50%;
        //  margin: auto;
        //  background: $secondary-color;
        //  transition: all 0.2s ease-in;
        //}
        //
        //&:hover{
        //  &:before{
        //    opacity: 0.5;
        //    width: 60px;
        //    height: 60px;
        //    min-width: 60px;
        //    min-height: 60px;
        //  }
        //}
      }
    }
  }
}
