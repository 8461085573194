.ui-text {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--bold {
    font-weight: 600;
  }

  &--medium {
    font-weight: 500;

  }

  &--light {
    font-weight: 300;

  }
}