.dr-category-wrapper{
  position: relative;
  width: 100%;

  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: $secondary-color;
  }

  @include break-max($mob){
    display: none;
  }
}

.dr-category{
  width: 100%;
  position: relative;

  @include break-max($tab){
    padding: 0 10px;
  }

  &__list{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    //margin: 0 -20px;
    margin: 0;
    white-space: nowrap;

    @media (max-width: 1200px) {
      margin: 0;
    }

    @include break-max($mob){
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__item{
    display: inline-block;
    padding: 0 20px;

    @include break-max($mob){
      width: calc(33.33% - 20px);
    }
  }

  &__item--all-category{
    padding-left: 0;
  }

  &__more {
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    &.active:before {
      position: absolute;
      background: #ede4cf;
      width: 100%;
      height: 42px;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 998;
    }
  }

  &__link{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 10px 0 10px 0;
    line-height: 1.4;
    font-size: 14px;
    color: $gray-font-color;
    border-bottom: 3px solid transparent;
    transition: all 0.4s ease;
    z-index: 999;

    &.selected{
      color: $black-color;
      font-weight: 500;
    }

    .ico{
      display: none;
      height: 34px;
      width: 48px;
      margin-bottom: 5px;
      background-size: 33px !important;
      background-position: center bottom !important;
      background-repeat: no-repeat !important;

      &.ico-more{
        background: url("../../images/ics/category/more.svg");
      }

      &.ico-all{
        background: url("../../images/ics/category/category.svg");
      }
      &.ico-other{
        background: url("../../images/ics/category/category.svg");
      }
      &.ico-medical{
        background: url("../../images/ics/category/medical.svg");
      }
      &.ico-beauty_and_wellness{
        background: url("../../images/ics/category/beauty.svg");
      }
      &.ico-sport{
        background: url("../../images/ics/category/GYM.svg");
      }
      &.ico-events_and_entertainment{
        background: url("../../images/ics/category/events.svg");
      }
      &.ico-personal_meetings_and_services{
        background: url("../../images/ics/category/presonal-meetings.svg");
      }
      &.ico-education{
        background: url("../../images/ics/category/education.svg");
      }
      &.ico-officials{
        background: url("../../images/ics/category/officials.svg");
      }
      &.ico-supermarkets{
        background: url("../../images/ics/category/supermarkets.svg");
      }
    }

    &:hover{
      color: $black-color;
      border-color: $black-color;
    }
  }

  &__all {
    .txt {
      color: $black-color;
      font-weight: 500;
    }
  }

  &__list-dropdown {
    display: none;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: absolute;
    background: #ede4cf;
    padding: 10px 0 0;
    right: 0;
    z-index: 1001;
    top: 100%;

    &.show {
      display: flex;
    }
  }

  &.has-parent-cat-dropdown{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .dr-category__list-main--dropdown{
      position: relative;
      margin-right: 15px;
      background: #ede4cf;

      &:before{
        content: '';
        position: absolute;
        display: block;
        width: 1500px;
        height: 100%;
        right: 100%;
        top: 0;
        background: #ede4cf;
      }

      .parent-main-category{
        display: flex;
        height: 42px;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        width: 240px;
        min-width: 240px;
        padding-left: 35px;
        position: relative;

        .txt{
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        &:after{
          content: '';
          display: block;
          height: 42px;
          width: 40px;
          margin-left: 20px;
          background: url("../../images/ics/arrow-down.svg") no-repeat;
          background-position: center left;
          background-size: 15px;
        }
      }

      .dr-category__parent-list{
        position: absolute;
        top: 65px;
        left: -20px;
        width: 100%;
        min-width: 260px;
        padding: 10px 0;
        background: #ede4cf;
        border: 1px solid rgba(#fff, 0.3);
        visibility: hidden;
        opacity: 0;
        @include transition;
        z-index: -1;

        @include break-max($tab){
          left: -28px;
        }

        .dr-category__item{
          display: block;
          width: 100%;
          padding: 0 10px;

          &.dr-category__item-selected{
            /*display: none;*/ // commented by dimka to allow select parent category from second level categories
          }

          .dr-category__link{
            flex-direction: row;
            justify-content: flex-start;
            text-align: left;
            border-bottom: none;
            padding: 2px 10px 2px 0;
            min-height: 36px;

            .txt{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .ico{
              display: block;
              margin-bottom: 0;
              margin-right: 10px;
              height: 33px;
              width: 36px;
              min-width: 36px;
              background-size: 22px !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
            }
          }
        }
      }

      &:hover{
        .dr-category__parent-list{
          z-index: 9999;
          visibility: visible;
          opacity: 1;
          top: 42px;
        }
      }
    }

    .dr-category__list{
      width: calc(100% - 255px);
      margin: 0;

      .dr-category__item--all-category{
        display: none !important;
      }
    }
  }
}