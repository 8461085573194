.dr-breadcrumbs {
  background: $white-color;

  &__item {
    margin-top: 11px;
    display: inline-block;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    &:not(:first-of-type) {
      &:before {
        display: block;
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50px;
        background-color: $gray-font-color;
        left: -19px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    a {
      color: $gray-font-color;
      @include transition;
    }
    &.current {
      a {
        color: $base-color;
      }
    }
  }
}