body #iubenda-cs-banner .iubenda-cs-container .iubenda-cs-content{
	box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1) !important;
}

#iubenda-cs-banner .iubenda-cs-container #iubenda-cs-title{
	font-family: 'Mulish', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	color: $black-color !important;
	margin: 0 0 8px 0 !important;
	padding: 0 !important;
}

#iubenda-cs-banner .iubenda-cs-container .iub-p{
	font-family: 'Noto Sans', sans-serif !important;
	color: $black-color !important;
}

body #iubenda-iframe #iubFooterBtnContainer button,
html body #iubenda-cs-banner .iubenda-cs-opt-group button{
	padding: 4px 20px !important;
	min-height: 36px !important;
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'Mulish', sans-serif !important;
	line-height: 1.2 !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	border-radius: 0 !important;
	color: $black-color;
	border: none !important;
	cursor: pointer !important;
	-webkit-tap-highlight-color: transparent;
	@include transition;
}

body #iubenda-iframe .iub-btn,
body #iubenda-iframe .iub-btn-consent,
html body #iubenda-cs-banner .iubenda-cs-opt-group button,
html body .iubenda-alert button.iubenda-button-cancel{
	background-color: $secondary-color !important;
	min-height: 36px !important;
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	font-family: 'Mulish', sans-serif !important;
	line-height: 1.2 !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	border-radius: 0 !important;
	color: $black-color;
	cursor: pointer !important;
	-webkit-tap-highlight-color: transparent;
	@include transition;
}
body #iubenda-iframe .iub-btn,
body #iubenda-iframe .iub-btn-consent,
html body #iubenda-cs-banner .iubenda-cs-opt-group button:hover,
html body .iubenda-alert button.iubenda-button-cancel:hover{
	background-color: darken($secondary-color, 10%);
}

body #iubenda-iframe #iubFooterBtnContainer button,
html body #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-accept-btn,
html body #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary,
html body .iubenda-alert button.iubenda-button-confirm{
	background-color: $base-color !important;
	color: $white-color !important;
}
body #iubenda-iframe #iubFooterBtnContainer button,
html body #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-accept-btn:hover,
html body #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary:hover,
html body .iubenda-alert button.iubenda-button-confirm:hover{
	background-color: darken($base-color, 10%) !important;
	color: $white-color !important;
}

body #purposes-content-container .purposes-content .purposes-header{
	padding-bottom: 10px !important;
}

body #iubenda-iframe .iub-consent-buttons{
	padding-top: 12px !important;
}

body #purposes-container .purposes-header .purposes-header-right .iub-btn.iub-btn-cp{
	display: none !important;
}

.footer__bottom .iubenda-embed.iubenda-black{
	font-size: 12px !important;
	color: $white-color !important;
	margin: 0 30px !important;
	font-weight: normal !important;
	padding: 0 !important;
	background: transparent !important;
	@include transition;

	&:hover {
		text-decoration: underline !important;
		background: transparent !important;
	}
	@media screen and(max-width: 1024px) {
		margin: 0 10px !important;
	}
	@media screen and(max-width: 768px) {
		font-size: 14px !important;
		text-decoration: underline !important;
	}
}