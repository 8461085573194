.easy-to-use{
  position: relative;
  background:  #f1f1f1;

  @include break-min($mob + 1){
    background:  #f1f1f1 url("../../images/easy-to-use/main-CTA.jpg");
    background-position: center right ;
    background-repeat: no-repeat;
    background-size: 570px;
  }

  @include break-min($tab){
    background-size: 900px;
    background-position: center right -100px;
  }

  .easy-to-use__image{
    @include break-max($mob){
      background:  #f1f1f1 url("../../images/easy-to-use/main-CTA.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 900px;
    }
  }

  &__body{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include break-max($mob){
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &__content{
    padding: 40px 15px;
    width: 50%;
    position: relative;
    z-index: 2;

    .ui-section-title{
      margin-bottom: 10px;
    }

    .txt{
      font-weight: 100;
      margin-bottom: 20px;
    }

    @include break-max($tab){
      padding: 40px 30px;
    }

    @include break-max($mob){
      padding: 40px 20px;
      width: 100%;
      max-width: 400px;
    }
  }

  &__image{
    height: 380px;
    width: 50%;
    background-position: center right;

    @include break-max($mob){
      width: 100%;
      height: 300px;
    }
  }



  &.about-us__easy-use{
    background:  #f1f1f1;

    @include break-min($tab + 1){
      //background:  #f1f1f1 url("../../images/about-us-page/rating-hero-img.jpg");
      background:  #f1f1f1 url("../../images/about-us-page/about-us-team2.jpg");
      background-position: bottom right 0;
      background-repeat: no-repeat;
      background-size: 880px;
      //background-size: 550px;
    }

    @include break-max(1770px){
      background-size: 710px;
    }

    @include break-max(1365px){
      background-size: 770px;
      background-position: bottom right -296px;
    }

    .easy-to-use__image {
      @include break-max($tab) {
        //background: #f1f1f1 url("../../images/about-us-page/rating-hero-img.jpg");
        background: #f1f1f1 url("../../images/about-us-page/about-us-team2.jpg");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 640px;
        //background-size: 560px;
      }

      @include break-max($mob) {
        background-size: 450px;
      }
    }
  }

  &.sport{
    background:  #000;

    @include break-min($mob + 1){
      background:  #000 url("../../images/easy-to-use/sport-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 900px;
      background-position: center right -200px;
    }

    .easy-to-use__content{
      .ui-section-title,
      .txt{
        color: $white-color;
      }
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #000 url("../../images/easy-to-use/sport-CTA.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 900px;
      }
    }
  }

  &.medical{
    background:  #f1f1f1;

    @include break-min($mob + 1){
      background:  #f1f1f1 url("../../images/easy-to-use/medical-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 630px;
      background-position: center right -200px;
    }

    .easy-to-use__content{
      @include break-max($mob){
        padding-bottom: 20px;
      }
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f1f1f1 url("../../images/easy-to-use/medical-CTA.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 450px;
      }
    }
  }

  &.beauty_and_wellness{ //appearance
    background:  #f1f1f1;

    @include break-min($mob + 1){
      background:  #f1f1f1 url("../../images/easy-to-use/beauty-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 900px;
      background-position: center right -200px;
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f1f1f1 url("../../images/easy-to-use/beauty-CTA.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 900px;
      }
    }
  }

  &.education{
    background:  #f6f6f6;

    @include break-min($mob + 1){
      background:  #f6f6f6 url("../../images/easy-to-use/education-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 900px;
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f6f6f6 url("../../images/easy-to-use/education-CTA.jpg");
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 700px;
      }
    }
  }

  &.events_and_entertainment{
    background:  #f1f1f1;

    @include break-min($mob + 1){
      background:  #f1f1f1 url("../../images/easy-to-use/events-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 750px;
    }

    @include break-min($tab){
      background-size: 790px;
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f6f6f6 url("../../images/easy-to-use/events-CTA.jpg");
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 870px;
      }
    }
  }

  &.officials{
    background:  #000;

    @include break-min($mob + 1){
      background:  #000 url("../../images/easy-to-use/business-services-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 850px;
      background-position: center right;
    }

    .easy-to-use__content{
      .ui-section-title,
      .txt{
        color: $white-color;
      }
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #000 url("../../images/easy-to-use/business-services-CTA.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 770px;
      }
    }
  }

  &.personal_meetings_and_services{ //appearance
    background:  #f1f1f1;

    @include break-min($mob + 1){
      background:  #f1f1f1 url("../../images/easy-to-use/personal-services-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: 570px;
    }

    @include break-min($tab){
      background-size: 680px;
      background-position: center right ;
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f1f1f1 url("../../images/easy-to-use/personal-services-CTA.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 490px;
      }
    }
  }

  &.supermarkets {
    @include break-min($mob + 1){
      background:  #f1f1f1 url("../../images/easy-to-use/supermarkets-CTA.jpg");
      background-position: center right ;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .easy-to-use__image{
      @include break-max($mob){
        background:  #f1f1f1 url("../../images/easy-to-use/supermarkets-CTA.jpg");
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}