.rating-stars {
  display: inline-flex;
  align-items: center;
  margin: 0 -5px;

  &__item {
    position: relative;
    width: 13px;
    height: 13px;
    background: url('../../images/ics/rating/rating-star.svg') no-repeat center;
    background-size: 13px;
    margin: 0 5px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 13px;
      height: 13px;
      background: url('../../images/ics/rating/rating-star-base.svg') no-repeat center;
      background-size: 13px;
    }
  }

  &[data-rating="1"] {
    .rating-stars__item:nth-child(1):before {
      content: '';
    }
  }

  &[data-rating="1.5"] {
    .rating-stars__item:nth-child(1):before {
      content: '';
    }

    .rating-stars__item:nth-child(2):before {
      content: '';
      left: 1px;
      width: 50%;
      background-position: left;
    }
  }

  &[data-rating="2"] {
    .rating-stars__item:nth-child(-n+2):before {
      content: '';
    }
  }

  &[data-rating="2.5"] {
    .rating-stars__item:nth-child(-n+2):before {
      content: '';
    }

    .rating-stars__item:nth-child(3):before {
      content: '';
      width: 50%;
      left: 1px;
      background-position: left;
    }
  }

  &[data-rating="3"] {
    .rating-stars__item:nth-child(-n+3):before {
      content: '';
    }
  }

  &[data-rating="3.5"] {
    .rating-stars__item:nth-child(-n+3):before {
      content: '';
    }

    .rating-stars__item:nth-child(4):before {
      content: '';
      width: 50%;
      left: 1px;
      background-position: left;
    }
  }

  &[data-rating="4"] {
    .rating-stars__item:nth-child(-n+4):before {
      content: '';
    }
  }

  &[data-rating="4.5"] {
    .rating-stars__item:nth-child(-n+4):before {
      content: '';
    }

    .rating-stars__item:nth-child(5):before {
      content: '';
      width: 50%;
      left: 1px;
      background-position: left;
    }
  }

  &[data-rating="5"] {
    .rating-stars__item:nth-child(-n+5):before {
      content: '';
    }
  }
}