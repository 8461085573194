.rating-block{
  max-width: 540px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;

  @include break-max($mob){
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
  }

  li{
    margin: 10px 15px;
    flex: 1;

    @include break-max($mob){
      width: calc(50% - 15px);
      margin: 10px 0;
      text-align: center;
    }

    img{
      height: 40px;
      display: inline-block;

      &.FinancesOnline{

      }
    }

    .rating-block--stars{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-top: -5px;

      svg{
        height: 12px;
        width: 13px;

        path{
          fill: $base-color;
        }
      }

      .rate{
        margin-left: 10px;
      }
    }

    &:hover{
      .rating-block--stars{

        i{
          color: $base-color;
        }

        .rate{

        }
      }
    }
  }


}