.layout-error {
  .header__button {
    margin-left: auto;
  }
}

.error-404 {
  padding: 50px 0;

  &__image {
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: $mob) {
      max-width: 200px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__number {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 16px;
    font-weight: 300;
    color: $gray-font-color;
  }

  &__link {
    display: block;
    color: $base-color;
    text-decoration: underline;
  }

  &__btn {
    margin-top: 15px;

    &:before {
      background-image: url(../../images/ics/arrow-right-white.svg);
    }
  }
}