.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  &.fade.show {
    opacity: 1;
  }
  &.fade {
    opacity: 0;
    transition: opacity .15s linear;
  }

  @media (max-width: $tab) {
    padding: 0 15px;
  }

  @include break-max($mob){
    padding: 0;
  }

  .modal-dialog {
    position: relative;
    width: 100%;
    pointer-events: none !important;
    max-width: 700px;
    margin: 1.75rem auto;
    background: $white-color;

    @include break-max($mob){
      display: block;
      width: auto;
      margin: 10px;
      max-width: 760px;
    }
  }

  .modal-image {
    position: relative;
    width: 50px;
    height: 50px;
    top: 25px;
    left: -10px;
    border-radius: 50px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    z-index: 1099;

    &:before{
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background: url("../../images/ics/close-black.svg") no-repeat center;
      background-size: 16px;
    }

    @media (max-width: $mob) {
      top: 5px;
    }
  }
  .modal-content {
    border: none;
    border-radius: 0;
  }

  &.modal-company{

    .company-map-container{
      height: 500px;
      width: 700px;

      @include break-max($tab){
        height: 400px;
        width: 100%
      }

      @include break-max($mob){
        height: 300px;
        width: 100%;
      }

      .mapboxgl-canvas{
        height: 500px;
        width: 700px;

        @include break-max($tab){
          height: 400px;
          width: 100%
        }

        @include break-max($mob){
          height: 300px;
          width: 100%;
        }
      }
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000;
  &.fade {
    opacity: 0;
    transition: opacity .15s linear;
  }
  &.show {
    opacity: .5;
  }
}

.modal-open {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  height: 100%;
}