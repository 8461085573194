.owl-carousel{
  .owl-dots{
    position: relative;
    text-align: center;

    .owl-dot{
      margin: 5px;
      position: relative;
      z-index: 2;

      span{
        box-sizing: border-box;
        display: block;
        height: 10px;
        width: 10px;
        border: 1px solid #000;
        border-radius: 50%;
        background: #fff;
        transition: all 0.2s ease;
      }

      &:hover{
        opacity: 0.5;
      }

      &.active{
        span{
          background: #000;
        }
        &:hover{
          opacity: 1;
        }
      }
    }
  }

  .owl-nav{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      position: relative;
      height: 40px;
      width: 40px;

      span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        text-indent: -999px;
        overflow: hidden;
        transition: all 0.3s ease;
      }

      &.owl-next{
        span{
          background: url("../../images/ics/arrow-slider.svg") no-repeat center;
          background-size: 28px;
        }
      }
      &.owl-prev{
        transform: rotate(180deg);
        span{
          background: url("../../images/ics/arrow-slider.svg") no-repeat center;
          background-size: 28px;
        }
      }
    }
  }

  &.dot-white{
    .owl-dot{
      span{
        border: 1px solid #fff;
        background: #fff;
      }

      &.active{
        span{
          background: #000;
        }
      }
    }
  }
}