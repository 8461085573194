.listing-cover {
  position: relative;
  padding: 20px 0;
  overflow: hidden;
  //padding: 120px 0 150px;
  //background: url('../../images/list-my-business-page/listing-cover-mob.svg');
  //background-position: center right 30%;
  //background-repeat: no-repeat;
  //background-size: 500px;
  //
  //@media (max-width: $tab) {
  //  background-position: center right;
  //}

  //@media (max-width: $mob) {
  //  padding: 40px 0 430px;
  //  background: url('../../images/list-my-business-page/listing-cover-mob.svg');
  //  background-position: center 85%;
  //  background-repeat: no-repeat;
  //  background-size: 380px;
  //  &:before, &:after {
  //    display: none;
  //  }
  //}

  &:before {
    background: #faf8f2;
    width: 130px;
    height: 130px;
    position: absolute;
    content: '';
    bottom: 50px;
    left: 100px;
    border-radius: 50%;
    z-index: -1;
  }
  &:after {
    background: #f1f1f1;
    width: 50px;
    height: 50px;
    position: absolute;
    content: '';
    bottom: 30px;
    left: 230px;
    border-radius: 50%;
    z-index: -1;
  }

  &__wrapper {
    display: flex;
    //align-items: center;
    align-items: center;
    justify-content: center;

    @media (max-width: $mob) {
      flex-direction: column;
    }
  }

  &__content {
    padding: 20px 0;
    @media (max-width: $mob) {
      text-align: center;
    }
  }

  &__title {
    font-size: 60px;
    line-height: 60px;

    @media (max-width: $mob) {
      font-size: 50px;
      margin-bottom: 10px;
    }
  }

  &__btn {
    margin-top: 35px;
    @media (max-width: $mob) {
      margin-top: 20px;
    }
  }

  &__image {
    max-width: 40%;
    width: 100%;
    min-width: 420px;
    min-height: 366px;
    position: relative;
    text-align: right;
    padding-left: 40px;

    @include break-max($tab){
      padding-left: 0;
      min-width: 300px;
    }

    @include break-max($mob){
      min-width: 200px;
      position: static;
      text-align: center;
      margin-bottom: 80px;
    }

    &:before{
      content: '';
      display: inline-block;
      width: 380px;
      height: 360px;
      position: relative;
      top: 0;
      left: 0;
      background: url('../../images/list-my-business-page/listing-cover-mob.svg');
      background-position: center right 0;
      background-repeat: no-repeat;
      background-size: 380px;

      @include break-max($tab){
        margin-right: -10px;
        position: absolute;
        right: 0;
        top: 0;
        background-position: center left;
        z-index: -1;
      }

      @include break-max($mob){
        width: 100%;
        background-position: center center;
        left: 0;
        top: auto;
        bottom: 0;
        z-index: 2;
      }
    }

    .page__content-cursive{
      text-align: left;
      position: absolute;
      top: 56%;
      left: 0;
      width: 210px;
      padding-left: 60px;

      @include break-max($tab){
        top: auto;
        bottom: 15px;
        left: -90px;
      }

      @include break-max($mob){
        top: 0;
        left: auto;
        right: auto;
        margin: 85px auto 0 auto;
        bottom: auto;
        width: auto;
        padding: 0;
        position: relative;
        text-align: center;
      }

      &:after{
        content: '';
        left: 10px;
        bottom: -48px;
        position: absolute;
        display: inline-block;
        height: 100px;
        width: 70px;
        background: url("../../images/cta/arrow-CTA.svg")no-repeat center;
        transform: scale(1 , -1) rotate(-80deg);

        @include break-max($tab){
          top: auto;
          left: 15px;
          bottom: -31px;
          transform: scale(1, -1) rotate(-100deg);
        }

        @include break-max($mob){
          height: 80px;
          width: 56px;
          top: -90px;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: auto;
          transform: scale(-1, -1) rotate(-180deg);
        }
      }
    }
  }
}


.listing-advantages {
  padding-bottom: 80px;

  @media (max-width: $mob) {
    padding-bottom: 100px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    border-top: 2px solid $gray-background-color;

    @media (max-width: $mob) {
      flex-wrap: wrap;
    }
  }

  &__item {
    max-width: 25%;
    width: 100%;

    @media (max-width: $mob) {
      max-width: 50%;
      margin-bottom: 40px;
    }
  }

  &__icon {
    width: 50px;
    margin: 0 auto 5px;
  }

  &__text {
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1em;
  }
}

.sbm-listing {
  background: #f1f1f1;

  @media (max-width: $mob) {
    padding-bottom: 60px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: $mob) {
      flex-direction: column;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;

    &-wrapper {
      width: 235px;
      height: 235px;
      border-radius: 50%;
      overflow: hidden;
      margin: -20px 0;
      @media (max-width: $mob) {
        margin: -70px 0;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 55%;
    margin-left: 40px;

    @media (max-width: $mob) {
      max-width: 100%;
      margin-left: 0;
      margin-top: 100px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.1em;

    @media (max-width: $mob) {
      text-align: center;
    }

    &:first-of-type {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      @media (max-width: $mob) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
  }

  &__sbm-logo {
    max-width: 230px;
    width: 100%;
    margin-left: 10px;
    margin-top: -15px;
    @media (max-width: $mob) {
      margin: 10px 0;
    }
  }
}

.listing-info {
  padding: 45px 0;

  @media (max-width: $tab) {
    padding: 55px 0 45px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    @media (max-width: $tab) {
      flex-direction: column;
    }
  }

  &__text {
    max-width: 30%;
    margin-right: auto;
    @media (max-width: $tab) {
      max-width: 100%;
      margin: 0 0 30px;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    max-width: 60%;

    @media (max-width: $tab) {
      max-width: 100%;
      justify-content: center;
    }
  }

  &__item {
    max-width: calc(33.333% - 10px);
    width: 100%;
    margin: 0 5px 20px;

    @media (max-width: $mob) {
      max-width: calc(50% - 10px);
      margin-bottom: 15px;
    }

    &-title {
      font-size: 14px;
      color: $gray-font-color;
      text-align: center;
    }
  }

  &__image {
    width: 190px;
    height: 180px;
    margin-bottom: 15px;
    @media (max-width: $tab) {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
}

.listing-info-two {
  &__wrapper {
    display: flex;
    align-items: center;
    @media (max-width: $mob) {
      flex-direction: column;
    }
  }

  &__image {
    margin: 0 0 -40px -40px;

    @media (max-width: $tab) {
      max-width: 65%;
    }

    @media (max-width: $mob) {
     order: 2;
      margin: 25px 0 -40px 0;
    }
  }

  &__text {
    max-width: 40%;
    margin: 0 auto;
    @media (max-width: $mob) {
      max-width: 100%;
      text-align: center;
    }
  }
}

.cta-dark-listing {
  padding: 60px 0 45px;
  background: $black-color;

  @media (max-width: $mob) {
    padding: 90px 0 50px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    @media (max-width: $mob) {
      flex-direction: column;
    }
  }

  &__text {
    max-width: 55%;
    font-size: 18px;
    color: $white-color;
    margin-right: 70px;

    @media (max-width: $tab) {
      margin-left: 10px;
    }

    @media (max-width: $mob) {
      max-width: 280px;
      margin: 0 0 20px 0;
      text-align: center;
    }

    &--bold {
      font-size: 26px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &-wrapper {
      position: relative;
      z-index: 1;
      width: 220px;
      height: 220px;
      border-radius: 50%;

      &:before {
        position: absolute;
        content: '';
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #323230;
        bottom: 0;
        left: -15px;
        z-index: -1;
      }
    }
  }
}


.cta-listing {
  padding-top: 55px;
  background: #f2f2f2 url('../../images/list-my-business-page/cta-listing.png');
  background-position: bottom left 15%;
  background-repeat: no-repeat;

  @media (max-width: $tab) {
    background-position: bottom left -30%;
  }

  @media (max-width: $mob) {
    padding-top: 60px;
    background-position: bottom center;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $mob) {
      flex-direction: column;
    }
  }

  &__image {
    width: 100%;
    max-width: 50%;
    height: 340px;
    @media (max-width: $mob) {
      height: 390px;
      order: 2;
    }
  }

  &__content {
    width: 100%;
    max-width: 30%;
    @media (max-width: $mob) {
      max-width: 100%;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 25px;
  }
}