.location-select__loader {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.location-select__button {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.active{
    z-index: 1040;
  }

  @media (max-width: $mob) {
    position: static;
  }
}

.location-select__form {

  .close-mob-popup{
    display: none;
    position: absolute;
    border: none !important;
    outline: none !important;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url(../../images/ics/close-modal.svg) no-repeat center;
    cursor: pointer;
    z-index: 10;

    @include break-max($mob){
      display: block;
    }
  }

  @media (max-width: $mob) {
    display: none;
    position: absolute;
    width: 100%;
    flex: 1;
    min-width: 0;
    left: 0;
    top: 0;

    &.opened {
      display: block;
      z-index: 1040;
    }
  }
}

.location-select__label {
  display: none;

  @media (max-width: $mob) {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0;
  }

  &:before {
    @media (max-width: $mob) {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-left: 5px;
      margin-right: 0;
      content: '';
      background-image: url(../../images/ics/map-marker.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.location-select__input {
  background: $white url(../../images/ics/map-marker.svg) no-repeat center left 15px;
  background-size: 12px;
  padding-left: 40px;
  max-width: 205px;
  border-color: transparent !important;
  cursor: pointer;

  &:focus {
    border-color: $border-color !important;
    cursor: text;
  }

  @media (min-width: 769px) and (max-width: $tab) {
    max-width: 150px;
  }

  @media (max-width: $mob) {
    max-width: 100%;
    width: 100%;
    border-color: $border-color !important;
    padding-right: 60px;
  }
}

.location-select__search {
  position: absolute;
  top: 60px;
  width: 100%;

  &:before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    top: 14px;
    left: 20px;
    background-image: url(../../images/ics/search-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  //&.state, &.city, &.country {
  //  top: 103px;
  //}
  //
  //&.state.city {
  //  top: 144px;
  //}

  &-input {
    height: 40px;
    width: 100%;
    border-left: none !important;
    border-right: none !important;
    font-size: 14px;
    font-weight: 300;
    padding-left: 40px;
  }
}

.location-select__wrapper {
  display: none;
  position: absolute;
  width: 250px;
  height: 320px;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  z-index: 1040;
  background: $gray-background-color;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  //&.city, &.state {
  //  height: 413px;
  //}
  //
  //&.state.city {
  //  height: 453px;
  //}

  //&:before {
  //  position: absolute;
  //  content: '';
  //  top: -2px;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  width: 4px;
  //  height: 4px;
  //  border-radius: 50px;
  //  background: $black-color;
  //  z-index: 1050;
  //}

  @include break-max($tab) {
    left: auto;
    right: 0;
    transform: translateX(0);

    &:before {
      left: auto;
      transform: translateX(0);
      right: 36px;
    }
  }

  @include break-max($mob) {
    width: 100%;
    top: 38px;
    &:before {
      display: none;
    }
  }
}

.location-select__default {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 40px;
  margin: 10px 0;
  padding: 10px 20px;
  background: $secondary-color;
  color: $black-color;
  font-weight: 600;
  font-size: 14px;
  @include transition;

  &:after {
    display: inline-flex;
    content: '';
    align-items: center;
    width: 16px;
    height: 16px;
    background-image: url(../../images/ics/globe.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    background: darken($secondary-color, 5%);
  }
}

.location-select__list {
  background: $gray-background-color;
  height: 230px;
  width: calc(100% - 10px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  margin: 10px 10px 0 0;
  top: 60px;
  padding: 0 25px;
  cursor: default;

  //&.state, &.city, &.country {
  //  top: 103px;
  //}
  //
  //&.states, &.cities {
  //  top: 144px;
  //
  //  .location-select__link {
  //    &.any {
  //      top: 144px;
  //    }
  //  }
  //}

  //&.state.cities {
  //  top: 184px;
  //
  //  .location-select__link {
  //    &.any {
  //      top: 184px;
  //    }
  //  }
  //}

  &::-webkit-scrollbar {
    width: 6px;
    opacity: 1;
    visibility: visible;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background: $border-color;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: $base-color;
  }
}

.location-select__item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:not(.app-item) {
    margin-bottom: 30px;
  }

  &.active {
    background-color: #ffffff;
  }
}

.location-select__flag {
  margin-right: 10px;
}

.location-select__text {
  font-size: 14px;
  color: $gray-font-color;
  font-weight: 300;
  cursor: text;
}

.location-select__request {
  font-weight: 500;
  color: $black-color;
  cursor: text;
}

.location-select__link {
  font-size: 14px;
  color: $gray-font-color;
  font-weight: 300;
  cursor: pointer;
  @include transition;

  &:hover {
    color: $black-color;
  }

  &.selected {
    font-weight: 600;
    color: $black-color;
  }

  &.any {
    position: fixed;
    width: calc(100% - 25px);
    padding: 10px 0 10px 25px;
    left: 0;
    top: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;;
    font-weight: 400;
    background: $gray-background-color;
    font-weight: 400;
    color: $black-color;
  }
}

.location-select__current {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  min-height: 30px;
  top: 60px;
  z-index: 1050;
  background: $gray-background-color;
  border-top: 1px solid $border-color;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 10px 25px;
    cursor: pointer;

    &:nth-child(2) {
      border-top: 1px solid $border-color;
    }
  }

  &-selected {
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-change {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    color: $gray-font-color;
    @include transition;

    &:hover {
      color: $black-color;
    }
  }

  &-flag {
    margin-right: 5px;
  }

  &-text {
    color: $black-color;
    font-size: 14px;
    font-weight: 600;
  }
}

// Header fixes for widget
.sb-widget-iframe {
  .location-select__input {
    @media (min-width: 769px) and (max-width: $tab) {
      max-width: 250px;
    }
  }
}