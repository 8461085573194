.key-benefits-section{
  background: $gray-background-color;

  .key-benefits--list{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    width: 100%;

    @include break-max($mob){
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .key-benefits--item{
      text-align: left;
      flex: 1;
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: 0 15px;

      @include break-max($mob){
        width: 50%;
        flex: 50%;
      }

      @include break-max(600px){
        width: 100%;
        flex: 100%;
      }

      .key-benefits--value{
        margin-right: 15px;
        font-family: "Mulish", sans-serif;
        font-size: 30px;
        font-weight: 900;
        color: $black-color;
        line-height: 1.2;
        text-transform: uppercase;
      }

      .key-benefits--name{
        line-height: 1.5;
        font-size: 14px;
        color: $gray-font-color;
      }
    }
  }
}