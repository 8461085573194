@import "vars";
@import "mixins";
@import "fonts";
@import "settings/settings";

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

input, select, button, textarea {
  font-family: 'Mulish', sans-serif;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus, &:hover {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &::-ms-expand {
    display: none;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $base-color;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.4;
  font-family: "Mulish", sans-serif;
  background-color: $white-color;
  color: $black-color;

  footer {
    margin-top: auto;
  }
}