.page {
  .page__main-block {
    .page__main--content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      min-height: 430px;

      @include break-max($mob) {
        flex-direction: column-reverse;
      }
    }

    .page__main-block-left {
      min-height: 300px;
      width: 510px;

      @include break-min($mob + 1) {
        min-height: 430px;
      }

      @include break-max(1200px) {
        width: 400px;
      }

      @include break-max($tab) {
        width: 350px;
      }

      @include break-max($mob) {
        min-height: 170px;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    .page__main-block-right {
      width: calc(100% - 530px);
      position: relative;
      padding: 40px 0;

      @include break-max(1200px) {
        width: calc(100% - 420px);
      }

      @include break-max($tab) {
        width: calc(100% - 370px);
      }

      @include break-max($mob) {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        padding: 25px 20px 80px 20px;
        text-align: center;
        margin-bottom: 0;
      }

      .home-page__content-title-thin {
        font-weight: 300;
        font-size: 32px;
        letter-spacing: 1px;
        margin-bottom: 40px;

        @include break-max($tab) {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      .page__main-search {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-top: 40px;

        @media (max-width: $tab) {
          flex-direction: column;
          width: 100%;
          margin-top: 30px;
        }

        select, input {
          height: 40px;
        }
      }

      .page__content-cursive {
        position: absolute;
        bottom: -50px;
        right: 0;
        z-index: 1;

        @media (max-width: $tab) {
          left: 0;
          right: auto;
        }

        @media (max-width: $mob) {
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          white-space: nowrap;
        }

        &:after {
          position: absolute;
          content: '';
          top: -35px;
          width: 50px;
          height: 50px;
          background: url('../../images/ics/arrow-cursive.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transform: rotate(190deg);

          @media (min-width: $mob) and (max-width: $tab) {
            left: -50px;
            transform: rotate(190deg) scale(-1, 1);
          }
        }
      }
    }
  }
}

.page__main-block {
  position: relative;

  &.page__main-block-medical {
    background: #f5f5f5;

    .page__main-block-left {
      position: relative;


      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #edeef0 url("../../images/hero-images/heroimage-medical.jpg") no-repeat;
        background-size: 470px;
        background-position: top -100px center;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right 20px;
          background-size: 600px;
          -webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  &.page__main-block-beauty_and_wellness { //page__main-block-appearance
    background: #f8f8f8;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #dddcda url("../../images/hero-images/heroimage-beauty.jpg") no-repeat;
        background-position: top center;
        background-size: 370px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right -50px;
          background-size: 560px;
          -webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  &.page__main-block-education {
    background: #d8c799;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #d8c799 url("../../images/hero-images/heroimage-education.jpg") no-repeat;
        background-position: top -30px center;
        background-size: 370px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right -120px;
          background-size: 500px;
          //-webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          //clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  &.page__main-block-sport {
    background: #2d2d2d;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #000 url("../../images/hero-images/heroimage-sport.jpg") no-repeat;
        background-position: top -10px center;
        background-size: 370px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right -30px;
          background-size: 550px;
          -webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }

    .page__main-block-right {
      background: #2d2d2d;
      .ui-hero-title,
      .ui-title,
      .ui-subtitle {
        color: $white-color;
      }

      .input-block{
        .dr-input{
          background: #fff;
        }
      }

      .page__content-cursive {
        color: $white-color;

        &:after {
          background: url('../../images/ics/arrow-cursive-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }

  &.page__main-block-officials {
    background: #2d2d2d;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #090b0a url("../../images/hero-images/heroimage-business-services.jpg") no-repeat;
        background-position: top center;
        background-size: 370px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right -10px;
          background-size: 550px;
          -webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }

    .page__main-block-right {
      background: #2d2d2d;
      .ui-hero-title,
      .ui-title,
      .ui-subtitle {
        color: $white-color;
      }

      .input-block{
        .dr-input{
          background: #fff;
        }
      }

      .page__content-cursive {
        color: $white-color;

        &:after {
          background: url('../../images/ics/arrow-cursive-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }

  &.page__main-block-events_and_entertainment{
    background: #e6d3ad;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #e6d3ad url("../../images/hero-images/heroimage-events.jpg") no-repeat;
        background-position: center;
        background-size: 405px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right -40px;
          background-size: 500px;
          //-webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          //clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  &.page__main-block-personal_meetings_and_services{
    background: #e8e8e8;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #e8e8e8 url("../../images/hero-images/heroimage-personal-services.jpg") no-repeat;
        background-position: top -14px center;
        background-size: 405px;

        @include break-min($mob + 1) {
          min-width: 1500px;
          background-position: center right ;
          background-size: 530px;
          -webkit-clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 0, 93% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  &.page__main-block-supermarkets {
    background: #e7e6e7;

    .page__main-block-left {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #e7e6e7 url("../../images/hero-images/heroimage-supermarkets.png") no-repeat;
        background-size: 650px;
        background-position: bottom right;

        @include break-max($tab) {
          background-size: 500px;
        }

        @include break-max($small-lap) {
          background-size: 550px;
          background-position: 70% bottom;
        }

        @include break-max($mob + 1) {
          min-width: 500px;
          background-position: 50% top;
          background-size: 450px;
        }
      }
    }
  }
}