.promotion-block {
  position: relative;
  padding: 60px 0 50px;
  background: #f9f6ee;

  .custom-informer {
    right: 0;
    top: 42px;
    padding: 0 60px 0 30px;

    .small-title {
      padding: 0 0 0 15px;

      &:after {
        margin-left: 10px;
        background: url("../../images/ics/special-offer.svg") no-repeat center;
      }
    }

    .shape {
      height: 190px;
      width: 190px;
      top: -20px;
      right: 0;

      &:before {
        top: 0;
        left: 0;
        background: $secondary-alt-color;
      }
    }

    @include break-max($tab) {
      display: none;
    }
  }

  &.special-offer {
    background: $white-color;
  }

  @media (max-width: $mob) {
    padding: 40px 0 30px;
  }

  &__title {
    margin-bottom: 45px;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 25%;
    margin-bottom: 35px;
    background: $white-color;
    text-align: center;

    @media (max-width: $tab) {
      max-width: 50%;
      margin-bottom: 35px;
    }

    @media (max-width: $mob) {
      max-width: 100%;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 5px;

      a {
        color: $black-color;
      }
    }


    &-footer {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: auto;
    }
  }

  &__info {
    width: 100%;
    margin-top: 45px;
    padding: 20px 25px;
  }

  &__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    position: absolute;

    &-wrapper {
      position: absolute;
      top: -20px;
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 50px;
      overflow: hidden;
      flex-shrink: 0;

      &.icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        background: $gray-background-color;
        overflow: visible;

        img {
          width: 50px;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
          left: -5px;
        }
      }
    }
  }

  &__expiration {
    font-size: 12px;
    font-weight: 300;
    color: $gray-font-color;

    &-date {
      font-size: 14px;
      font-weight: 600;
      color: $base-color;
    }
  }

  &__discount {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin: 10px 0 15px;
    height: 35px;
    background: $gray-background-color;

    &-value {
      margin-left: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #880000;
    }
  }

  &__desc {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin-bottom: 10px;
  }

  &__company {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    &-link {
      color: $black-color;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__location {
    position: relative;
    display: flex;
    flex-direction: column;

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    &-address {
      color: $gray-font-color;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-toggle {
      color: $gray-font-color;
      font-size: 14px;
      text-decoration: underline;
      margin-top: 5px;
      text-align: left;
    }

    &-list {
      position: absolute;
      flex-direction: column;
      background: $gray-background-color;
      left: -25px;
      top: 45px;
      padding: 20px 19px;
      width: calc(100% + 50px);
      max-height: 200px;
      overflow-y: auto;
      z-index: 1;
    }

    &-item {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }
  }

  &__apply {
    margin-left: auto;

    &:after {
      background-image: url('../../images/ics/arrow-right.svg');
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &-btn {
      text-align: center;
      text-decoration: underline;

      &:after {
        background-image: url('../../images/ics/arrow-right.svg');
      }
    }
  }
}
