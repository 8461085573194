//Mulish
@include font-face("Mulish", "../../fonts/mulish/Mulish-Light", 300, normal);
@include font-face("Mulish", "../../fonts/mulish/Mulish-Regular", 400, normal);
@include font-face("Mulish", "../../fonts/mulish/Mulish-Medium", 500, normal);
@include font-face("Mulish", "../../fonts/mulish/Mulish-Bold", 600, normal);
@include font-face("Mulish", "../../fonts/mulish/Mulish-Black", 900, normal);

//Raph Lanok Future
@include font-face("Raph Lanok", "../../fonts/RaphLanok/RaphLanokFuture", 400, normal);

//Noto Sans
@include font-face("Noto Sans", "../../fonts/noto-sans/NotoSans-Light", 300, normal);
@include font-face("Noto Sans", "../../fonts/noto-sans/NotoSans-Regular", 400, normal);