.confirm-delete-review-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;

  &.active {
    display: flex;
  }

  .confirm-delete-review-dialog__body {
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    text-align: center;

    .confirm-delete-review-dialog__header {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      margin-top: 0;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      margin-bottom: 20px;
    }

    button {
      background-color: #007bff;
      border: none;
      color: #fff;
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #0062cc;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
      }
    }
  }
}