
.go-to {
  padding: 50px 0;

  &__image {
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: $mob) {
      max-width: 200px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    max-width: 90%;
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__text {
    font-size: 16px;
    font-weight: 300;
    color: $gray-font-color;
    margin-bottom: 30px;
  }

  &__link {
    font-weight: 600;
    text-decoration: underline;
  }

  &__btn-back {

    &:before {
      background-image: url(../../images/ics/arrow-right-white.svg);
    }
  }

  &__btn-leave {
    margin-top: 10px;

    &:after {
      background-image: url(../../images/ics/ghost.svg);
    }
  }
}