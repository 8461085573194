.search-page {
  background-color: $gray-background-color;
  padding-top: 0;

  &-widget {
    @media (max-width: 768px) {
      margin-top: 20px;

      .filters__toggle {
        margin-top: -20px;
      }
    }
  }

  &.promotions-search {
    background: none;

    @media (max-width: $mob) {
      .search__header {
        display: block;
        padding: 0;

        &-wrapper {
          margin: 0 -15px;
        }

        .search__header-item {
          &.search__header-item-title {
            display: none;
          }
        }
      }
    }

    .search__header-item-btn--companies {
      display: inline-flex !important;
    }

    .search__header-item-btn--promotions {
      display: none !important;
    }

    .search__header-item-swicher {
      display: none;
    }


    .promotions-result__title {
      display: none;
    }

    @media (max-width: $mob) {
      .promotions-result__title {
        display: block;
        width: 100%;
        text-align: center;
        margin: 15px 0 20px;
      }
    }
  }

  &.search-page--map-view {
    .search__header-item-swicher {
      margin-left: 0 !important;
    }
  }

  .dr-category-wrapper {
    @media (max-width: $mob) {
      display: none;
    }
  }

  .search__header {
    position: relative;
    padding: 25px 0 15px 0;
    //&:before {
    //  position: absolute;
    //  content: '';
    //  width: 100%;
    //  height: 45px;
    //  top: 0;
    //  background: $white-color;
    //}

    @include break-max($mob) {
      display: none;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: baseline;
      z-index: 1000;
      justify-content: space-between;

      .search__header-item {
        &.search__header-item-title {
          display: flex;
          align-items: baseline;
          font-size: 22px;
          margin-right: 15px;

          @include break-max($mob) {
            margin-right: 0;
          }
        }

        &.search__header-item-swicher {
          margin-left: auto;
          @include break-max($mob) {
            display: none;
          }
        }

        &.search__header-show-filters {
          display: none;

          .filters__show {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 600;
            text-decoration: underline;

            &:before {
              display: inline-flex;
              content: '';
              width: 23px;
              height: 23px;
              margin-right: 8px;
              margin-bottom: 4px;
              background: url("../../images/ics/filter.svg") no-repeat center;
              background-size: 18px;
            }

            &.active {
              &:before {
                margin-bottom: 0;
                background: url("../../images/ics/close-black.svg") no-repeat center;
                background-size: 12px;
              }
            }
          }
        }

        &.search__header-item-btn--companies {
          display: none;

          @media (max-width: $mob) {
            width: 100%;
            margin-bottom: 30px;
          }

          .search__header-companies {
            border: 1px solid $border-color;

            &:before {
              width: 20px;
              height: 20px;
              background-image: url('../../images/ics/companies-btn.svg');
            }

            @media (max-width: $mob) {
              width: 100%;
              justify-content: center;
              border-left: none;
              border-right: none;
            }
          }
        }

        &.search__header-item-btn--promotions {
          display: inline-flex;
          margin-left: 25px;

          .search__header-promotions {
            border: 1px solid $border-color;

            &:before {
              width: 25px;
              height: 25px;
              margin-left: -5px;
              background-image: url('../../images/ics/discount.svg');
            }

            &:after {
              background-image: url('../../images/ics/arrow-right.svg');
            }
          }
        }
      }
    }

    &-pre {
      font-size: 18px;
      font-weight: 600;
      margin-right: 10px;
    }

    &-title {
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
    }

    &-text {
      margin: 0 10px;
    }

    &-location {
      text-decoration: underline;

      .location-select__button {
        display: block;
      }

      .location-select__input {
        background: none;
        padding-left: 15px;
        font-size: 20px;

        &:focus {
          background: $white;
        }

        @media (max-width: $tab) and (min-width: $mob) {
            max-width: 145px;
        }
      }
    }
  }

  .sort-bar {
    display: none;
    //display: flex;
    //flex-wrap: wrap;
    //align-items: center;
    //margin-bottom: 15px;
    //position: relative;

    &__view {
      color: $black-color;
      margin-right: auto;
      text-decoration: underline;
      position: relative;
      z-index: 5;

      &:before {
        width: 23px;
        height: 23px;
        margin-bottom: 4px;
        background-image: url(../../images/ics/show-on-map.svg);
      }
    }

    &__found {
      margin-right: 20px;
      font-weight: 300;

      @media (max-width: $mob) {
        display: none;
      }
    }

    &__category {
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      @media (max-width: $mob) {
        display: none;
      }
    }

    @include break-max($mob) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding-top: 5px;
      margin-bottom: 30px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -15px;
        width: calc(100% + 30px);
        height: 60%;
        background: #fff;
      }
    }
  }

  .promotions {
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: $tab) {
      margin: 0 -15px 20px;
    }


    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
      @media (max-width: $tab) {
        display: none;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      width: calc(50% - 10px);
      margin: 5px;
      background: #f5f2eb;
      @include transition;

      &:hover {
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);

        .promotions__btn-apply {
          background: #ede4cf;

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &__info {
      max-width: 65%;
      width: 100%;
      margin-right: auto;
      padding: 15px 0;
    }

    &__image {
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      position: absolute;

      &-wrapper {
        position: relative;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        margin: 10px 15px 0 10px;
        border-radius: 50px;
        overflow: hidden;
        background: $gray-background-color;

        &.icon {
          overflow: visible;

          img {
            width: 40px;
            height: auto;
            top: 3px;
            left: 0;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        color: $black-color;
      }
    }

    &__expiration {
      font-size: 12px;
      font-weight: 300;
      color: $gray-font-color;

      &-date {
        font-weight: 600;
        color: $base-color;
      }
    }

    &__company {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      margin: 5px 0 0;

      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }

      a {
        color: $black-color;
      }
    }

    &__location {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;

      &-multiple {
        position: relative;
        max-width: 100%;
        min-width: 0;
        width: 100%;

        .promotions__location-address {
          max-width: 60%;
        }
      }

      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }

      a {
        color: $gray-font-color;
      }

      &-address {
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include transition;

        &:hover {
          color: $black-color;
        }
      }

      &-toggle {
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        text-decoration: underline;
        margin-left: auto;
      }

      &-list {
        position: absolute;
        flex-direction: column;
        background: #fff;
        left: 0;
        top: 25px;
        padding: 20px 19px;
        width: 100%;
        z-index: 1;

        &.show {
          display: flex;
        }
      }

      &-item {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;

        .promotions__location-address {
          max-width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__discount {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: #880000;
    }

    &__btn {
      &-apply {
        position: relative;
        margin-top: auto;

        &:hover {
          background: #ede4cf;
        }
      }

      &-more {
        margin-top: 15px;
        text-align: center;
        text-decoration: underline;

        &:after {
          background-image: url('../../images/ics/arrow-right.svg');
        }

        @media (max-width: $tab) {
          display: none;
        }
      }

      &-view {
        width: 100%;
        justify-content: center;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        color: $base-color;
        margin-bottom: 10px;

        @media (min-width: 1025px) {
          display: none;
        }

        &:before {
          width: 25px;
          height: 25px;
          background-image: url(../../images/ics/discount.svg);
        }

        &:after {
          background-image: url(../../images/ics/arrow-right.svg);
        }
      }
    }
  }

  .search-result {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    @media (max-width: $tab) {
      &.promotions-result {
        margin: 25px -5px 0;
      }
    }

    @media (max-width: $mob) {
      &.promotions-result {
        margin: 10px -5px 0;
      }
    }

    //@include break-min($tab + 1){
    //  display: grid;
    //  grid-gap: 15px;
    //  grid-template-columns: repeat(auto-fit,minmax(460px,1fr));
    //}
    //
    //@include break-min(1804px){
    //  grid-template-columns: repeat(auto-fit,minmax(580px,1fr));
    //}

    .company {
      //position: relative;
      //background: $white;
      //border: 1px solid $border-color;
      //padding: 0;
      margin: 0 5px 10px 5px;
      width: calc(50% - 10px);
      //flex: 0 0 calc(50% - 10px);
      //transition: .3s ease;
      //
      //&:hover {
      //  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
      //}

      @media (max-width: $tab) {
        width: calc(50% - 10px);
        //flex: 0 0 calc(50% - 10px);
        margin: 0 5px 35px;
      }

      @media (max-width: $mob) {
        width: 100%;
        //flex: 0 0 100%;
        margin: 0 0 35px;
      }

      &__header {
        //display: flex;
        //align-items: center;
        //flex-wrap: wrap;
        //min-height: 138px;
        min-height: 120px;
        padding: 25px 25px 15px 25px;

        @media (max-width: $tab) {
          min-height: auto;
        }
      }

      //&__logo {
      //  position: relative;
      //  width: 80px;
      //  height: 80px;
      //  border-radius: 50px;
      //  overflow: hidden;
      //  margin-right: 25px;
      //  background: $gray-background-color;
      //
      //  @media (max-width: $tab) {
      //    position: absolute;
      //    top: -20px;
      //  }
      //
      //  &.icon {
      //    overflow: visible;
      //    width: 60px;
      //    height: 60px;
      //
      //    img {
      //      height: auto;
      //      top: 0;
      //      width: 50px;
      //      left: -10px;
      //    }
      //  }
      //
      //  img {
      //    display: block;
      //    max-width: 100%;
      //    height: 100%;
      //    object-fit: cover;
      //    margin: auto;
      //    position: absolute;
      //  }
      //
      //}

      &__info {
        width: 75%;
        margin-bottom: auto;

        @media (max-width: $tab) {
          width: 100%;
          margin-top: 45px;
          margin-bottom: 0;
        }
      }

      &__title {
        margin-bottom: 5px;
      }

      //&__category {
      //  font-size: 12px;
      //  font-weight: 500;
      //  color: $base-color;
      //  line-height: 1;
      //}

      &__location {
        padding: 10px 30px;

        &.company__multiple-location {
          cursor: pointer;

          @include break-max($mob) {
            justify-content: flex-start;
            padding-right: 60px;

            .service__book {
              display: inline-block;
            }

            .toggle {
              position: absolute;
              right: 0;
              top: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 100%;
              background-size: 14px;
            }
          }
        }

        &-address {
          max-width: 65%;

          @media (max-width: $mob) {
            display: none;
          }
        }

        &-show {
          font-size: 12px;
          color: $gray-font-color;
          text-decoration: underline;
          cursor: pointer;

          @media (max-width: $mob) {
            display: none;
          }
        }

        .toggle {
          display: inline-block;
          width: 16px;
          height: 16px;
          content: "";
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(../../images/ics/arrow-down.svg);
        }

        .location__list {
          position: absolute;
          background: $gray-background-color;
          left: 0;
          top: 100%;
          width: 100%;
          z-index: 1;

          &-wrapper {
            padding: 20px 30px 20px 55px;
            width: 100%;
          }

          &-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 20px;

            &:last-child {
              padding-bottom: 0;
            }

            .company__location-address {
              &:before {
                display: none;
              }
            }

            .choice-company-location {
              display: block;
              cursor: pointer;
              color: $gray-font-color;
              @include transition;

              &:hover {
                color: $black-color;
              }
            }

            .company__location-show {
              display: inline-block;
              opacity: 0;
              @include transition;

              @include break-max($mob) {
                opacity: 1;
              }
            }

            &:hover {
              .company__location-show {
                opacity: 1;
              }
            }
          }
        }

        .collapse.show {
          display: flex;
        }

        a.company__location-address {
          text-decoration: underline;
          display: none;

          @media (max-width: $mob) {
            display: block;
            margin-right: 0;
          }
        }
      }


      .company__desc {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        margin-top: 8px;
      }

      .service__list {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px 0 25px;

        .service__item {
          position: relative;
          transition: .3s ease;
          cursor: pointer;

          &:hover {
            background: $secondary-alt-color;

            .service__body {
              .service__title {
                color: $black-color;
              }

              .service__price {
                opacity: 0;
              }

              .service__book {
                .service__button {
                  opacity: 1;
                }
              }
            }
          }

          .service__body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 40px;
            padding: 0 15px 0 65px;
            cursor: pointer;

            @media (max-width: $tab) {
              padding: 15px;
            }

            &-modal {
              display: flex;
              align-items: center;
              //justify-content: space-between;
              flex: 1;
            }

            .service__title {
              width: 50%;
              font-size: 14px;
              font-weight: 500;
              margin-right: 10px;
              color: $gray-font-color;
              word-break: break-word;
              transition: .3s ease;
            }

            .service__duration {
              width: 20%;
              font-size: 14px;
              font-weight: 300;
              color: $gray-font-color;
            }

            .service__price {
              width: 25%;
              font-size: 14px;
              font-weight: 600;
              text-align: left;
              transition: .2s ease;
            }

            .service__book {
              position: absolute;
              right: 0;
              width: auto;
              text-align: left;
              height: 100%;

              @media (max-width: $tab) {
                display: none;
              }

              .service__button {
                background: darken($secondary-color, 5%);
                height: 100%;
                opacity: 0;
                padding: 0 20px 0 30px;

                &:after {
                  background-image: url(../../images/ics/arrow-right.svg);
                }
              }
            }
          }
        }

        .service__more {
          padding-left: 65px;
          margin-top: auto;
          padding-top: 10px;

          @media (max-width: $tab) {
            text-align: center;
            padding-left: 0;
          }

          a {
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .dr-pagination {
      @include break-max($mob) {
        margin-top: 10px;
      }
    }
  }

  .nothing-found {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    background: $gray-background-color;

    &--nhs {

      .nothing-found__icon-wrapper {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }

      .nothing-found__icon {
        width: 60px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__content {
      max-width: 50%;
      margin-left: 50px;
    }

    &__icon {
      position: absolute;
      width: 45px;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);

      &-wrapper {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        background: $white-color;
        margin-bottom: 15px;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      color: $black-color;
    }

    &__text {
      font-size: 16px;
      font-weight: 300;
      color: $gray-font-color;
      margin-top: 10px;
    }

    &__link {
      font-weight: 500;
      color: $black-color;
      text-decoration: underline;
    }
  }
}