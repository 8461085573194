// Colors
$white: #ffffff;
$base-color: #c2a65c;
$secondary-color: #f3edde;
$secondary-alt-color: #faf8f2;
$black-color: #000000;
$gray-font-color: #737373;
$white-color: #fff;
$gray-background-color: #f8f8f8;
$border-color: #e8e8e8;
$link-color: #354cfd;

// Media breakpoints
$mob-small: 480px;
$mob: 768px;
$tab: 1024px;
$small-lap: 1200px;
$lap: 1366px;


@mixin break-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media (max-width: $point) {
    @content;
  }
}