// Top companies block
.top-companies {
  padding: 50px 0;
  background: $gray-background-color;

  &__title {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      background: $white-color;
      width: 100%;
      height: 65px;
      left: 0;
      top: -50px;
      z-index: -1;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .company {
    position: relative;
    border: 1px solid $border-color;
    background: $white-color;
    transition: .3s ease;
    width: 33.333%;
    margin-bottom: 40px;

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background: $black-color;
      opacity: 0;
      transition: .3s ease;
    }

    &:hover {
      box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
      &:before {
        opacity: 1;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;justify-content: flex-start;
      height: 100%;
    }

    &__header {
      flex-direction: column;
      padding: 25px 25px 10px 25px;
    }

    &__logo {
      position: absolute;
      top: -20px;
      width: 80px;
      height: 80px;
      border-radius: 50px;
      overflow: hidden;
      background: $gray-background-color;

      &.icon {
        overflow: visible;

        img {
          height: 70px;
          top: -3px;
          left: 0px;
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
        position: absolute;
      }

    }

    &__info {
      width: 100%;
      margin-top: 45px;
      margin-bottom: 0;
    }

    &__title {
      font-weight: 400;
      margin-bottom: 5px;

      a {
        color: $black-color;
      }
    }

    &__category {
      font-size: 12px;
      font-weight: 500;
      color: $base-color;
      line-height: 1;
    }

    &__desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 100%;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      margin-top: 5px;
      @supports not (display: -webkit-box) {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__data {
      justify-content: center;
    }


    &__location {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px 30px 20px 30px;
      justify-content: center;
      margin-bottom: 0px;
      margin-top: auto;
      border: none;

      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      &-address {
        display: block !important;
        max-width: 65%;
        color: $black-color;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
      }
    }

    &__stats {
      display: flex;
      align-items: center;
      justify-content: center;
      &-item {
        padding: 15px 20px;
        text-align: center;
        flex: 1;
        &.services {
          .company__stats-title:before {
            background-image: url(../../images/ics/services.svg);
          }
        }

        &.reviews {
          .company__stats-title:before {
            background-image: url(../../images/ics/reviews.svg);
          }
        }
      }
      &-amount {
        font-size: 14px;
        font-weight: 600;
        color: $black-color;
        margin-bottom: 5px;
      }
      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 300;
        color: $gray-font-color;

        &:before {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          content: "";
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}