@media (max-width: $tab) {
  .categories-opened {
    overflow: hidden;
    height: 100%;
  }
}

.form-search__input,
.form-search__select {
  height: 40px;
}

.select-block {
  position: relative;
  width: 220px;
  height: 40px;

  @media (max-width: $tab) {
    position: static;
    width: 100%;
    margin-bottom: 5px;
  }
}

.input-block {
  @media (max-width: $tab) {
    width: 100%;
  }

  input {
    border-left: none !important;

    &::placeholder {
      font-weight: 300;
    }

    @media (max-width: $tab) {
      width: 100%;
      border: 1px solid $border-color !important;
    }
  }
}

.select-block,
.input-block {
  @include break-max($tab) {
    width: 100%;

    .form-search__input,
    .form-search__select {
      width: 100%;
      max-width: 100%;
    }
  }
}

.form-search__select {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  border-right: none;
  background: $gray-background-color;
  padding: 0 60px 0 45px;
  height: 40px;

  &:before {
    content: '';
    display: inline-flex;
    position: absolute;
    left: 15px;
    width: 16px;
    height: 16px;
    pointer-events: none;
    background: url("../../images/ics/categories-folder.svg") no-repeat center;
    background-size: 16px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    right: 22px;
    bottom: 0;
    margin: auto;
    border: none;
    pointer-events: none;
    background: url("../../images/ics/select-arrow.svg") no-repeat center;
    background-size: 12px;
  }

  &[aria-expanded="true"] {
    width: 300px;

    @media (max-width: $tab) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1040;
    }

    &:after {
      @media (max-width: $tab) {
        right: 23px;
        background: url("../../images/ics/close-modal.svg") no-repeat center;
        width: 24px;
        height: 24px;
        background-size: 24px;
      }
    }
  }
}

.form-search__category-selected {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form-search__categories {
  position: absolute;
  width: 300px;
  padding: 15px 15px 0 15px;
  z-index: 1040;
  top: 100%;
  box-shadow: 0 5px 7px 1px rgba(0, 0, 0, 0.15);
  background: #fff;

  @media (max-width: $tab) {
    position: fixed;
    top: 40px;
    left: 0;
    height: calc(100vh - 40px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      opacity: 1;
      visibility: visible;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      background: $border-color;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: $base-color;
    }
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
  }

  &-title {
    display: flex;
    align-items: center;
    //max-width: 70%;
    position: relative;
    width: 100%;
    min-height: 36px;
    padding: 4px 0;
    font-size: 14px;
    text-align: left;
    color: $gray-font-color;
    cursor: pointer;
    @include transition;

    &:after{
        content:'';
        display: block;
        height: 100%;
        width: calc(100% + 60px);
        position: absolute;
        top: 0;
        left: -15px;
        z-index: -1;
        background: $gray-background-color;
        opacity: 0;
    }

    &:hover {
      color: $black-color;
    }

    &[aria-expanded="true"] {
      &:after{
        opacity: 1;
      }
    }

    @media (max-width: $tab) {
      font-size: 16px;
    }

    &:before {
      content: '';
      display: inline-flex;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      pointer-events: none;
      background-size: 20px;
      background: url("../../images/ics/category/category.svg") no-repeat center;
    }

    &.sport {
      &:before {
        background: url("../../images/ics/category/GYM.svg") no-repeat center;
      }
    }

    &.beauty_and_wellness {
      &:before {
        background: url("../../images/ics/category/beauty.svg") no-repeat center;
      }
    }

    &.education {
      &:before {
        background: url("../../images/ics/category/education.svg") no-repeat center;
      }
    }

    &.medical {
      &:before {
        background: url("../../images/ics/category/medical.svg") no-repeat center;
      }
    }

    &.events_and_entertainment {
      &:before {
        background: url("../../images/ics/category/events.svg") no-repeat center;
      }
    }

    &.officials {
      &:before {
        background: url("../../images/ics/category/officials.svg") no-repeat center;
      }
    }

    &.personal_meetings_and_services {
      &:before {
        background: url("../../images/ics/category/presonal-meetings.svg") no-repeat center;
      }
    }

  }

  &-toggle {
    position: relative;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    cursor: pointer;
    background: #f8f8f8 url("../../images/ics/select-arrow.svg") no-repeat center;
    background-size: 12px;
    @include transition;

    &[aria-expanded="true"] {
      transform: rotate(180deg);
    }
  }

  &-sublist {
    padding: 0 0 20px 30px;
    font-size: 14px;
    color: $gray-font-color;
    text-align: left;

    @media (max-width: $tab) {
      font-size: 16px;
    }
  }

  .categories-sublist__item {
    cursor: pointer;
    margin-bottom: 10px;
    @include transition;

    &.categories-sublist__item-all-in{
      color: $black-color;
    }

    &:hover {
      color: $black-color;
    }

    @media (max-width: $tab) {
      margin-bottom: 15px;
    }
  }
}

.form-search__input {
  border-left: none;
}

.form-search__submit {
  padding-left: 15px;
  padding-right: 35px;

  &:before {
    background: url(../../images/ics/search-submit.svg);
  }

  @include break-max($tab) {
    margin-right: auto;
    margin-top: 20px;
  }

  @include break-max($mob) {
    margin-left: auto;
  }
}