.company-page {
  padding-top: 0;
  background: $white-color;

  .company-page__cover {
    width: 100%;
    max-height: 150px;
    object-fit: cover;

    @media (max-width: $mob) {
      max-height: 100px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $black-color;
    text-align: center;
    margin-bottom: 25px;
  }

  .company {
    .company-name {
      font-size: 28px;
      margin-bottom: 30px;
      width: 70%;
    }

    .company-logo {
      display: inline-block;
      width: auto;
      max-width: 30%;
    }
  }

  .company-section {
    padding: 10px 0 20px;
    margin: 0 -15px;
  }

  // Company info
  .company-content {
    display: inline-block;
    width: 100%;

    @media (max-width: $tab) {
      display: flex;
      flex-direction: column;
    }

    &__right {
      display: flex;
      max-width: 600px;
      width: 100%;
      float: right;
      margin-left: 30px;

      @media (max-width: $tab) {
        max-width: 100%;
        justify-content: center;
        float: none;
        order: 2;
        margin: 0;
      }
    }
  }

  .company-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid $border-color;

    @media (max-width: $tab) {
      margin-bottom: 15px;
      padding: 0 15px;
      flex-direction: column;
      border: none;
    }

    &__body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      min-width: 30%;
      padding: 5px 0 10px;

      @media (max-width: 1200px) and (min-width: $tab) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: $tab) {
        flex-direction: column;
      }

      @media (max-width: $mob) {
        justify-content: center;
        padding: 0 0 15px;
      }

      .company-map__btn {
        display: none;
        &:after {
          background: url('../../images/ics/show-on-map.svg');
          width: 22px;
          height: 22px;
        }
        @include break-max($tab) {
          display: inline-flex;
          margin: 5px;
        }
      }

      .company-contacts__btn{
        display: none;

        @include break-max($tab){
          display: inline-flex;
          margin: 5px;
          order: 3;
        }

        @include break-max($mob) {
          order: 0;
        }
      }

      .company-promotions__btn {
        border: 1px solid $border-color;
        color: $base-color;
        margin: 5px;
        &:before {
          background-image: url('../../images/ics/discount.svg');
          width: 25px;
          height: 25px;
        }
        &:after {
          background-image: url('../../images/ics/arrow-right.svg');
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;

      @media (max-width: $mob) {
        width: 100%;
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &__image {
      position: relative;
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 50px;
      overflow: hidden;
      margin-right: 15px;
      flex-shrink: 0;

      &.icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        background: $gray-background-color;
        overflow: visible;

        img {
          width: 50px;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
          left: -5px;
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
        position: absolute;
      }
    }

    &__title {
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $tab) {
        font-size: 22px;
        text-transform: none;
        letter-spacing: 0;
        line-height: 1.3;
      }
    }

    &__data {
      display: flex;
      align-items: center;
      @media (max-width: $mob) {
        width: 100%;
        justify-content: center;
      }
    }

    &__reviews {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      margin-left: 20px;
      &:before {
        display: flex;
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background: url('../../images/ics/reviews-black.svg') no-repeat center;
        background-size: contain;
      }
    }
    &__btns {
      margin: -5px -5px -5px auto;

      @media (max-width: $tab) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 15px -5px -5px;
      }
    }
  }

  .company__contacts {
    position: relative;
    width: 100%;
    display: flex;
    padding: 30px 15px;
    margin-bottom: 20px;
    background: $gray-background-color;

    @media (max-width: $tab) {
      flex-wrap: wrap;
      padding: 0 0 20px 0;
    }

    @media (max-width: $mob) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }


    &-title {
      position: absolute;
      display: block;
      top: -50px;
      left: 20px;
      font-size: 30px;
      z-index: 1;

      @include break-max($tab){
        position: static;
        padding: 0 20px;
        text-align: left;
        margin: 20px 0;
      }
    }

    &-email {
      display: flex;
      margin-bottom: 30px;

      @media (max-width: $tab) {
        justify-content: center;
        padding: 0 20px;
        margin-bottom: 30px;
      }
    }

    &--left, &--right {
      flex: 1;
      flex-shrink: 0;
      padding: 0 20px;

      @media (max-width: $tab) {
        width: 100%;
        max-width: 50% !important;
        padding: 0;
      }

      @media (max-width: $mob) {
        max-width: 620px !important;
        width: auto;
        min-width: 300px;
      }
    }

    &--left {
      position: relative;
      @media (max-width: $tab) {
        order: 2;
        text-align: right;
      }
      @media (max-width: $mob) {
        order: 0;
      }

      .company-contacts__btn{
        @include break-max($tab){
          display: none;
        }
      }
    }
  }

  .company__map {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    @media (max-width: $tab) {
      width: 100%;
      height: 160px;
      border-radius: 0;
      overflow: hidden;
    }

    .mapboxgl-control-container {
      display: none;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin: -50px 25px -50px 0;

      @media (max-width: $tab) {
        display: none;
      }

      .map_popup__header {
        display: none;
        background: $gray-background-color;
        position: absolute;
        height: 40px;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 15px;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: $mob) {
          width: calc(100% - 30px);
        }
      }

      &.fullscreen {
        display: block;
        padding-top: 40px;
        position: fixed;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 600px;
        z-index: 1040;
        margin: 0;

        @media (max-width: $mob) {
          max-width: 100%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 0 15px;
        }

        .map_popup__header {
          display: flex;
        }

        .company__map {
          width: 600px;
          height: 600px;
          border-radius: 0 !important;
          backface-visibility: visible;
          transform: none;

          @media (max-width: $mob) {
            max-width: 100%;
            width: 100%;
            height: 80vh;
            margin-top: 40px;
          }

          .mapboxgl-control-container {
            display: block;
          }
        }

        .company__map-popup--open {
          display: none;
        }

        .company__map-popup--close {
          display: block;
        }
      }
    }

    &-popup--open {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
      height: 250px;
      border-radius: 50%;
      cursor: pointer;

      @media (max-width: $tab) {
        top: auto;
        left: auto;
        bottom: -10px;
        right: 30px;
        width: 35px;
        height: 35px;
        background-size: 17px;
      }
    }

    &-popup--close {
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 1099;
      background: url("../../images/ics/close-black.svg")no-repeat center;
      background-size: 16px;
    }
  }

  .company__links {
    &-list {
      margin-bottom: 20px;
      text-align: left;

      @media (max-width: $tab) {
        order: 2;
        padding: 0 20px 0 20px;
        margin-bottom: 20px;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 10px;

      @media (max-width: $tab) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.web {
        .company__links-link {
          text-decoration: underline;
          color: $base-color;
          font-weight: 500;
        }
      }

      &.phone {
        text-decoration: underline;
        color: $gray-font-color;
        @media (max-width: $tab) {
          .company__links-link {
            font-size: 16px;
          }
        }
      }

      &.address {
        .company__links-link {
          font-size: 14px;

          @media (max-width: $tab) {
            font-size: 16px;
          }
        }
      }

      &:before {
        display: inline-flex;
        flex-shrink: 0;
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background-size: contain;
      }

      &.web:before {
        background: url('../../images/ics/link.svg') no-repeat center;
      }

      &.address:before {
        background: url('../../images/ics/map-marker.svg') no-repeat center;
      }

      &.phone:before {
        background: url('../../images/ics/phone.svg') no-repeat center;
      }
      &.email:before {
        background: url('../../images/ics/mail.svg') no-repeat center;
      }
    }

    &-link {
      font-size: 14px;
      font-weight: 300;
      color: $black-color;
    }
  }

  .company__email {
    border: 1px solid $secondary-color;

    &:hover {
      background: $secondary-color;
    }

    &:after {
      background: url('../../images/ics/mail.svg') no-repeat center;
    }
  }

  .company__schedule {
    position: relative;
    max-width: 320px;
    flex-shrink: 0;
    flex: 1;
    @media (max-width: $tab) {
      //max-width: 100%;
      padding: 0 20px 30px 20px;
    }

    &-title {
      position: absolute;
      display: block;
      top: -50px;
      left: 0;
      font-size: 30px;
      z-index: 1;

      @include break-max($tab){
        margin: 20px 0;
        position: static;
      }
      @include break-max($mob){
        font-size: 16px;
        font-weight: 500;
        font-family: "Mulish", sans-serif;
        margin-bottom: 5px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-day {
      font-size: 14px;
      font-weight: 300;
    }

    &-time {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .company__social {
    display: flex;
    align-items: center;
    margin-top: 50px;

    &-title {
      font-weight: 300;
      margin-right: auto;
    }
  }

  .company-about {
    padding: 0 15px;
    margin: 40px 0 30px;
    word-break: break-word;
    @media (max-width: $tab) {
      text-align: center;
      margin-bottom: 50px;
      padding: 0 20px;
    }

    .ui-section-title {
      margin-bottom: 10px;
      display: none;

      @include break-max($mob){
        display: block;
      }
    }

    &__desc {
      text-align: left !important;

      p{ // don't remove !!!!!!
        text-align: left !important;
        @extend .ui-small;
      }
    }
  }

  .company__nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 30px;

    &-item {
      padding: 20px;
      border: 1px solid $border-color;
      @include transition;

      &:hover {
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
      }
    }

    &-icon {
      width: 40px;
      height: auto;
      margin: 0 auto 10px;
    }

    &-title {
      font-size: 14px;
      font-weight: 600;
      color: $black-color;
    }
  }

  .company-promotions {
    .promotion-block {
      &__footer {
        &-btn {
          &:before {
            width: 20px;
            height: 20px;
            background-image: url('../../images/ics/add-promotions.svg');
          }
        }
      }
    }
  }

  // Gallery block
  .company-gallery {
    padding: 50px 0;

    &__title {
      margin-bottom: 25px;
    }
    &__list {
      display: flex;
      align-items: center;
      //column-count: 4;
      //column-gap: 1em;
      flex-wrap: wrap;
      margin: -5px;
    }
    &__item {
      max-width: calc(25% - 10px);
      width: 100%;
      height: 250px;
      margin: 5px;

      //display: inline-block;
      //margin: 0 0 1em;
      //width: 100%;

      @media (max-width: $tab) {
        max-width: calc(50% - 10px);
        width: 100%;
        height: 200px;
      }
    }
    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &__btn {
      margin-top: 30px;
      text-decoration: underline;
      &:before {
        width: 20px;
        height: 20px;
        background-image: url('../../images/ics/add-photo.svg');
      }
      &-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  // Our team block
  .company-providers {
    padding: 50px 0;
    background: $gray-background-color;
    text-align: center;

    @media (max-width: $mob) {
    padding: 50px 0 25px;
  }

    &__title {
      margin-bottom: 25px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: left;
      padding: 25px 0 0;
    }

    .provider {
      width: 20%;
      margin-bottom: 40px;
      background: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include break-max(1200px){
        width: 25%;
      }

      @include break-max($tab){
        width: 33.33%;
      }

      @include break-max($mob){
        width: 100%;
        max-width: 340px;
        margin-bottom: 25px;
      }

      &__body {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 25px;
        margin-top: 0;
        margin-bottom: auto;

        @media (max-width: $tab) {
          flex-direction: column;
          text-align: center;
        }
      }

      &__logo {
        position: absolute;
        top: -20px;
        width: 80px;
        height: 80px;
        border-radius: 50px;
        overflow: hidden;
        background: $white-color;

        &.icon {
          width: 60px;
          height: 60px;
        }

        &.icon {
          overflow: visible;

          img {
            width: 50px;
            height: 50px;
            top: 50%;
            transform: translateY(-50%);
            left: -10px;
          }
        }

        img {
          display: block;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          margin: auto;
          position: absolute;
        }

      }

      .provider__info {
        width: 100%;
        margin-top: 45px;
        margin-bottom: auto;
      }

      &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        white-space: normal;
        margin-bottom: 5px;

        a {
          color: $black-color;
        }
      }

      &__category {
        font-size: 12px;
        font-weight: 500;
        color: $base-color;
        line-height: 1;
      }

      &__data {
        margin: 25px 0;
      }

      &__details {
        display: flex;
        align-items: center;
        padding: 0 20px 10px;

        &-divider {
          display: block;
          width: 100%;
          background: $secondary-color;
          height: 2px;
          margin-right: 30px;
        }

        &-link {
          flex-shrink: 0;
          font-size: 14px;
          color: $gray-font-color;
          text-decoration: underline;
          display: inline-block;
          padding: 5px 0;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    &__btn {
      color: $base-color;
      text-decoration: underline;
      &:before {
        width: 20px;
        height: 20px;
        background-image: url('../../images/ics/add-team.svg');
      }
    }
  }

  // Top services block
  .top-services {
    padding: 45px 0;

    &__title {
      margin-bottom: 25px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    .service {
      //position: relative;
      //border: 1px solid $border-color;
      //transition: .3s ease;
      width: 33.333%;
      margin: 0;

      @include break-max($tab) {
        width: 50%;
      }

      @include break-max($mob) {
        width: 100%;
        margin-bottom: 15px;
      }

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }

      &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 4px;
        background-color: $black-color;
        opacity: 0;
        transition: .3s ease;
      }

      &:hover {
        //z-index: 1;
        //box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);

        &:after {
          opacity: 1;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        padding: 20px 25px 10px 25px;
      }

      &__image {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        margin-right: 15px;
        flex-shrink: 0;

        &.icon {
          overflow: visible;
        }

        img {
          object-fit: cover;
          height: 100%;
        }
      }

      &__info {
        width: calc(100% - 65px);
      }

      &__title {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 10px;
      }

      &__booked {
        font-size: 12px;
        font-weight: 600;
        color: $base-color;
        line-height: 1;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 20px 10px 25px;

        .service__body-one-row{
          display: flex;
          align-items: flex-start;
        }

        &-divider {
          display: block;
          flex-shrink: 0;
          width: 4px;
          height: 4px;
          border-radius: 50px;
          background: $base-color;
          margin: 6px 10px 0 10px;
        }
      }

      &__duration {
        font-size: 12px;
        font-weight: 300;
        color: $gray-font-color;
        white-space: nowrap;
      }

      &__description {
        font-size: 12px;
        font-weight: 300;
        color: $gray-font-color;
        //text-overflow: ellipsis;
        //overflow: hidden;
        //white-space: nowrap;
      }

      &__details {
        display: flex;
        align-items: center;
        padding: 0 20px 10px;

        &-divider {
          display: block;
          width: 100%;
          background: $secondary-color;
          height: 2px;
          margin-right: 30px;
        }

        &-link {
          flex-shrink: 0;
          font-size: 14px;
          color: $gray-font-color;
          text-decoration: underline;
          display: inline-block;
          padding: 5px 0;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .service__price {
          margin-left: 25px;
          margin-right: auto;
        }
      }

      &__price {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        margin-right: 25px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      &-btn {
        color: $base-color;
        text-align: center;
        text-decoration: underline;
        &:before {
          width: 20px;
          height: 20px;
          background-image: url('../../images/ics/add-services.svg');
        }
      }
    }

    .service-modal {
      .modal-company__footer {

        .service__price {
          @include break-min($mob + 1) {
            margin-right: 25px;
          }
          margin-left: 0;
        }
      }
    }
  }

  // Company reviews block

  .company-reviews {
    padding: 35px 0;

    &__header {
      position: relative;
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;
    }

    &__title {
      @media (max-width: $tab) {
        flex: 1;
        text-align: center;
      }
    }

    &__amount {
      margin-left: 20px;

      @media (max-width: $tab) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    &__wrapper {
      display: flex;

      @media (max-width: $tab) {
        flex-direction: column;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      &-wrapper {
        max-width: 50%;
        width: 100%;
        @media (max-width: 1250px) {
          max-width: 100%;
          padding-left: 25px;
        }
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px 10px 35px;
      border: 1px solid $gray-background-color;

      &-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }
      &-date {
        font-size: 12px;
        color: $gray-font-color;
        font-weight: 300;
        margin-right: 15px;
        line-height: 1;
      }
      &-rating {
        margin: 0 -5px;
        &-star {
          margin: 0 3px;
        }
      }
      &-delete {
        display: flex;
        justify-content: end;
      }
      &-delete-icon {
        margin-top: -15px;
        margin-right: -15px;
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #880000;
        text-decoration: underline;
        cursor: pointer;

        &:before {
          display: flex;
          width: 15px;
          height: 15px;
          content: "";
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          background-image: url(../../images/ics/clear-filters.svg);
        }
      }
      &-name {
        font-size: 14px;
        font-weight: 600;
        margin-right: auto;
        line-height: 1;

        @media (max-width: $tab) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &-heading {
        font-size: 14px;
        font-weight: 300;
      }
      &-text {
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
      }
    }
    &__image-wrapper {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      top: 10px;
      left: -30px;

      &.icon {
        img {
          width: 40px;
          height: 40px;
          top: 50%;
          transform: translateY(-50%);
          left: -10px;
        }
      }
    }

    &__image {
      object-fit: cover;
      height: 100%;
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 0;
      &-content {
        max-width: 50%;
        margin-left: 40px;
      }
      &-image {
        flex-shrink: 0;
      }
      &-title {
        font-size: 18px;
        font-weight: 600;
        color: $black-color;
      }
      &-text {
        font-size: 16px;
        font-weight: 300;
        color: $gray-font-color;
        margin-top: 10px;
      }
    }

    // Reviews form style
    &__form {
      position: relative;
      margin-top: 20px;
      &.not-logged {
        margin-top: 0;
        pointer-events: none;
        opacity: 0.5;
      }
      &-wrapper {
        width: 100%;
        max-width: calc(50% - 115px);
        margin: 0 0 0 115px;

        @media (max-width: $tab) {
          max-width: 100%;
          margin: 50px 0 0;
        }
      }
      &-title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }

      &-subtitle {
        font-weight: 300;
        text-align: center;
      }

      // Form review header

      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      &-image-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        margin-right: 15px;
        background: $gray-background-color;
      }

      &-image {
        object-fit: cover;
        height: 100%;
      }

      &-name {
        font-weight: 600;
      }

      &-logout {
        color: $gray-font-color;
        font-size: 14px;
        font-weight: 300;
        margin-left: auto;

        &:before {
          background: url('../../images/ics/logout.svg');
        }
      }

      // Form inputs error message
      &-error {
        width: 100%;
        font-size: 12px;
        font-weight: 300;
        color: #880000;
        margin-bottom: 10px;
      }
    }

    &__input, &__textarea {
      width: 100%;
      margin-bottom: 5px;
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
      }
    }

    &__rating {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      unicode-bidi: bidi-override;
      direction: rtl;
      margin: 0 -5px 5px;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        @media (max-width: $tab) {
          justify-content: center;
        }
      }

      label {
        position: relative;
        width: 15px;
        height: 15px;
        background: url('../../images/ics/rating/rating-star.svg') no-repeat center;
        background-size: 15px;
        margin: 0 5px;

        @media (max-width: $tab) {
          width: 20px;
          height: 20px;
          margin: 0 8px;
          background-size: 20px;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 15px;
          height: 15px;
          background: url('../../images/ics/rating/rating-star-base.svg') no-repeat center;
          background-size: 15px;

          @media (max-width: $mob) {
            width: 20px;
            height: 20px;
            background-size: 20px;
          }
        }
      }

      label:hover:before,
      label:hover ~ label:before {
        content: '';
      }

      input[type="radio"]:checked ~ label:before {
        content: '';
      }

      input[type="radio"] {
        display: none;
      }
    }


    // Post review button
    &__btn {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
      &-post {
        &:after {
          width: 20px;
          height: 20px;
          background-image: url('../../images/ics/send.svg');
        }
      }
    }

    &__login {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 -15px;
      //&:after {
      //  position: absolute;
      //  content: '';
      //  right: 90px;
      //  top: -50px;
      //  width: 50px;
      //  height: 50px;
      //  background: url('../../images/ics/arrow-cursive-base.svg') no-repeat center;
      //  background-size: contain;
      //}

      &-wrapper {
        margin-bottom: 30px;
      }

      &-title {
        text-align: center;
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 10px;
      }

      &-item {
        margin: 0 15px;

        &.fb:before {
          display: flex;
          content: '';
          width: 30px;
          height: 30px;
          background: url('../../images/ics/social/facebook.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &.tw:before {
          display: flex;
          content: '';
          width: 30px;
          height: 30px;
          background: url('../../images/ics/social/twitter.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &.gl:before {
          display: flex;
          content: '';
          width: 35px;
          height: 35px;
          background: url('../../images/ics/social/google.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }


  // Similar companies block
  .similar-companies {
    padding: 50px 0;
    background: $gray-background-color;

    &__title {
      margin-bottom: 60px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .company {
      width: 33.333%;
      margin: 0;

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: $black-color;
        opacity: 0;
        transition: .3s ease;
      }

      &:hover {
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);

        &:before {
          opacity: 1;
        }
      }

      &__header {
        flex-direction: column;
      }

      &__logo {
        position: absolute;
        top: -20px;
        width: 80px;
        height: 80px;
        border-radius: 50px;
        overflow: hidden;
        background: $gray-background-color;

        &.icon {
          overflow: visible;

          img {
            height: 70px;
            top: -10px;
            left: -5px;
          }
        }

        img {
          display: block;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          margin: auto;
          position: absolute;
        }

      }

      &__info {
        margin-top: 45px;
        margin-bottom: 0;
      }

      &__desc {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        margin-top: 10px;
        @supports not (display: -webkit-box) {
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }


      &__location {
        padding: 10px 30px;
        justify-content: center;

        &-icon {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }

        &-address {
          max-width: 65%;
          margin: 0;
        }
      }

      &__stats {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
          padding: 15px 20px;
          text-align: center;
          flex: 1;

          &.services {
            .company__stats-title:before {
              background-image: url(../../images/ics/services.svg);
            }
          }

          &.reviews {
            .company__stats-title:before {
              background-image: url(../../images/ics/reviews.svg);
            }
          }
        }

        &-amount {
          font-size: 14px;
          font-weight: 600;
          color: $black-color;
          margin-bottom: 5px;
        }

        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 300;
          color: $gray-font-color;

          &:before {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
  }
}