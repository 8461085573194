.dr-link {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @include transition;

  &--arrow {
    &:after {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-left: 10px;
      background-image: url(../../../images/ics/arrow-right-base.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &--icon-left {
    &:before {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    outline: none;
  }
}

