.dr-dropdown {
  position: relative;
  display: block;
  width: 100%;
  &.show {
    .dr-dropdown-menu {
      display: block;
    }
  }
}
.dr-dropdown-btn {
  display: block;
  max-width: 400px;
  width: 100%;
  padding: 10px 12px;
  border-radius: 0;
  border: 1px solid $border-color;
  text-align: left;
  background-color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.dr-dropdown-menu,
.autocomplete-suggestions {
  display: none;
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid $border-color;
  border-top: none;
  z-index: 5;
  &.scrollable {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
  }
  .dropdown-item,
  .autocomplete-suggestion{
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: $black-color;
    transition: all .25s ease;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}