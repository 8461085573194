// Font face mixin
@mixin font-face($font-family, $file-path, $font-weight, $font-style, $file-type: 'ttf', $file-format: 'truetype') {
  @font-face {
    font-family: $font-family;
    src:    url('#{$file-path}.eot'),
    url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.#{$file-type}') format($file-format),
    url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

// Transition mixin
@mixin transition() {
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -ms-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}