.dr-input {
  height: 38px;
  padding: 0 20px;
  border: 1px solid $border-color !important;
  color: $black-color;
  background: #fff;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    font-weight: 300;
  }
  &--icon {

  }
}
.dr-select {
  position: relative;
  height: 38px;
  padding: 0 20px;
  border: 1px solid $border-color !important;
  color: $black-color;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    font-weight: 300;
  }
  &--icon {

  }
}

.dr-textarea {
  min-height: 120px;
  padding: 10px 20px 0;
  border: 1px solid $border-color !important;
  color: $black-color;
  background: #fff;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
  &::placeholder {
    font-weight: 300;
  }
  &--icon {

  }
}