.footer {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 0;

    @media (max-width: $tab) {
      margin: 0 30px;
    }

    @media (max-width: $mob) {
      margin: 0;
      flex-direction: column;
      justify-content: stretch;
      align-items: center;
    }
  }

  &__col {
    &-one {
      display: flex;
      @media (max-width: $tab) {
        flex-direction: column;
        flex-shrink: 0;
        margin-right: 25px;
      }
      @media (max-width: $mob) {
        margin: 30px 0 0;
        order: 3;
      }
    }

    &-two {
      display: flex;
      flex: 1;

      @media (max-width: $small-lap) {
        flex: 0;
      }

      @media (max-width: $tab) {
        flex-direction: column;
        width: 25%;
      }
      @media (max-width: $mob) {
        width: auto;
        text-align: center;
        align-items: center;
      }
    }
  }

  &__logo {
    display: block;
    width: 100px;
    flex-shrink: 0;

    @media (max-width: $tab) {
      display: none;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    padding: 0 50px;

    @media (max-width: $tab) {
      padding: 0;
    }

    @media (max-width: $mob) {
      text-align: center;
    }

    &-text {
      position: relative;
      font-size: 14px;
      font-weight: 300;
      color: $black-color;
      padding: 0 0 15px 5px;
      @media (max-width: $mob) {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        width: 35px;
        height: 35px;
        left: -30px;
        bottom: -15px;
        background: url('../../images/ics/footer/arrow-cursive.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (max-width: $mob) {
          display: none;
        }
      }
    }

    &-button {
      &:after {
        width: 20px;
        height: 20px;
        background: url('../../images/ics/footer/add-company.svg');
      }
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mob) {
      margin-bottom: 20px;
      text-align: center;
    }

    &-item {
      width: calc(50% - 10px);
      margin-bottom: 10px;

      @media (max-width: $tab) {
        margin-bottom: 15px;
      }

      @media (max-width: $mob) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &-link {
      font-size: 14px;
      font-weight: 300;
      color: $black-color;
      @include transition;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: $mob) {
        font-size: 16px;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 32%;
    margin-right: 40px;

    @media (max-width: $small-lap) {
     display: none;
    }

    &-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;

      @media (max-width: $mob) {
        font-size: 16px;
      }
    }

    &-text {
      font-size: 12px;
      font-weight: 300;
      @media (max-width: $mob) {
        font-size: 14px;
      }
    }
  }

  &__social {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    flex: 0;

    @media (max-width: $mob) {
      margin: 0 -10px;
      justify-content: center;
    }

    &-item {
      margin: 0 10px;
      font-size: 14px;
      color: $black-color;

      &.email {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin: 0 10px 10px 15px;
        text-decoration: underline;

        @media (max-width: $mob) {
          margin: 0 0 10px;
          text-align: center;
          justify-content: center;
        }

        &:before {
          display: flex;
          content: '';
          width: 16px;
          height: 16px;
          margin-right: 10px;
          background: url('../../images/ics/mail.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      &.email-legal {
        width: 100%;
        margin: 0 10px 15px 15px;
        font-size: 12px;

        a {
          color: $black-color;
          text-decoration: underline;
        }
      }

      &.fb:before {
        display: flex;
        content: '';
        width: 30px;
        height: 30px;
        background: url('../../images/ics/social/facebook.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (max-width: $mob) {
          width: 35px;
          height: 35px;
        }
      }

      &.tw:before {
        display: flex;
        content: '';
        width: 30px;
        height: 30px;
        background: url('../../images/ics/social/twitter.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (max-width: $mob) {
          width: 35px;
          height: 35px;
        }
      }

      &.yt:before {
        display: flex;
        content: '';
        width: 28px;
        height: 28px;
        background: url('../../images/ics/social/youtube.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (max-width: $mob) {
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  &__scroll-top {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -60px;
    bottom: 25px;
    background: url('../../images/ics/footer/arrow-top.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;

    @media (max-width: $lap) {
      right: 0;
      bottom: 25px;
    }

    @media (max-width: $tab) {
      right: -20px;
      bottom: 20px;
    }

    @media (max-width: $mob) {
      width: 30px;
      height: 30px;
      right: 10px;
      bottom: 35px;
    }
  }

  &__bottom {
    background: $black-color;
    color: $white-color;
    padding: 10px 0;

    &-wrapper {
      display: flex;
      align-items: center;

      @media (max-width: $tab) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &-copyrights {
      font-size: 12px;
      margin-right: auto;

      @media (max-width: $tab) {
        margin: 0;
        order: 2;
      }
    }

    &-nav {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: $tab) {
        margin: 0 -20px 10px;
        justify-content: center;
      }

      &-item {
        font-size: 12px;
        color: $white-color;
        margin: 0 35px;
        @include transition;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: $tab) {
          margin: 0 10px;
        }

        @media (max-width: $mob) {
          font-size: 14px;
          text-decoration: underline;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


footer.footer{
  &.footer__wrapper-on-map{
    opacity: 0;
    display: none;
    .footer__wrapper{
      z-index: -1;
    }
  }

  &__wrapper-on-map--visible{
    background: #fff;

    .footer__wrapper{
      display: none;
    }
  }
}
