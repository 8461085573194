.home-page {
  padding-top: 0;
  padding-bottom: 50px;

  @include break-max($mob){
    padding-bottom: 30px;
  }

  .dr-category-wrapper{
    margin-bottom: 20px;

    &:before{
      height: 63%;
    }

    @include break-max($mob){
      padding: 10px 0;
      background: #fff;

      &:before{
        display: none;
      }
    }
  }

  .dr-category{

    &__link{
      padding: 0 0 5px 0;
      @include break-max($mob){
        padding: 10px 0;

        .txt{
          line-height: 1.2;
        }
      }

      .ico{
        display: inline-block;
      }
    }
  }

  .page__main-block-left.home-page__carousel{
    @include break-max($mob){
      width: 100%;
      background: $gray-background-color;
      padding:0 0 55px 0;
    }
  }

  .home-page__category-carousel{
    .home-page__category-carousel-item{
      text-align: center;
      position: relative;

      picture{
        width: 100%;
        text-align: center;

        img{
          @include break-min($mob + 1){
            display: inline-block;
            width: auto;
            max-width: 100%;
            max-height: 430px;
          }
        }
      }

      .home-page__category-name{
        font-weight: 600;
        text-align: center;
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;

        @include break-max($mob){
          left: auto;
          right: 0;
          padding-right: 50px;
          bottom: 0;
          top: 50%;
          max-width: 250px;
          text-align: right;
        }
      }
    }
    .owl-dots{
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;

      .owl-dot {
        margin: 5px 15px;
      }

      @include break-max($mob){
        bottom: -40px;
      }
    }
  }

  .home-page__advantage{
    padding: 0;

    .advantage-block{
      @include break-min($mob + 1){
        margin-top: 40px;
        padding-top: 30px;
        border-top: 2px solid #f8f8f8;
        margin-bottom: 10px;
      }

      @include break-max($mob) {
        padding: 10px 0;
      }
    }
  }

  .home-page__top-activities{
    padding: 35px 0 50px;
    position: relative;
    background: $gray-background-color;

    &:before{
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      background: #fff;
    }

    @include break-max($mob){
      background: #fff;
      padding: 0;

      &:before{
        display: none;
      }
    }

    .ui-section-title{
      position: relative;
      z-index: 2;
    }
  }


  @include break-max($mob){
    display: flex;
    flex-direction: column;


    .page__main-block{
      order: -3;
    }
    .dr-category-wrapper{
      order: -2;
      margin-bottom: 0;
    }
    .easy-to-use{
      order: -1;
    }
    .home-page__advantage{
      padding: 30px 0;
    }
  }
}