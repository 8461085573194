.ui-hero-title {
  font-size: 80px;
  font-weight: 900;
  line-height: 80px;
  color: $black-color;
  text-transform: uppercase;

  @include break-max($tab) {
    font-size: 54px;
    line-height: 55px;
  }
}

.ui-subtitle {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: $black-color;

  @include break-max($tab){
    font-size: 18px;
  }
}

.ui-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  color: $black-color;

  @include break-max($tab){
    font-size: 30px;
    line-height: 1.3;
  }
}

.ui-section-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.001em;
  color: $black-color;
}

.ui-cursive {
  font-family: 'Raph Lanok', serif;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.001em;
  color: $black-color;
  font-weight: 400;
}
.lang-pt .ui-cursive{
  font-family: cursive;
}


.ui-small {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.001em;
  color: $gray-font-color;
  font-weight: 300;
}

.ui-text-proxima {
  font-family: 'Noto Sans', sans-serif;
  &--dark {
    font-family: 'Noto Sans', sans-serif;
    color: #303030;
  }
  &--light {
    font-family: 'Noto Sans', sans-serif;
    color: #dddddd !important;
  }
  &--regular {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
  }
}

//line clamp
.line-clamp {
  display            : block;
  display            : -webkit-box;
  -webkit-box-orient : vertical;
  position           : relative;

  line-height        : 1.4;
  overflow           : hidden;
  text-overflow      : ellipsis;
  padding            : 0 !important;
}
.line-clamp:after {
  content    : '...';
  text-align : right;
  bottom     : 0;
  right      : 0;
  width      : 25%;
  display    : block;
  position   : absolute;
  height     : calc(1em * 1.4);
  background : linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
}
@supports (-webkit-line-clamp: 1) {
  .line-clamp:after {
    display : none !important;
  }
}
.line-clamp-1 {
  -webkit-line-clamp : 1;
  height             : calc(1em * 1.4 * 1);
}
.line-clamp-2 {
  -webkit-line-clamp : 2;
  height             : calc(1em * 1.4 * 2);
}
.line-clamp-3 {
  -webkit-line-clamp : 3;
  height             : calc(1em * 1.4 * 3);
}
.line-clamp-4 {
  -webkit-line-clamp : 4;
  height             : calc(1em * 1.4 * 4);
}
.line-clamp-5 {
  -webkit-line-clamp : 5;
  height             : calc(1em * 1.4 * 5);
}