@import "controls";
@import "text";
@import "button";
@import "link";
@import "input";
@import "checkbox";
@import "modal";

.container {
  max-width: 1200px !important;
}

.page {
  padding: 0 0 20px 0;
  @media (max-width: $mob) {
    padding: 0 0 22px 0;
  }

  &:not(.home-page){
    .page__main-block-right .ui-hero-title{
      display: none;
    }
  }

  &.page__sport{
    .section__advantage .advantage-block ul li:before{
      background: #fff;
    }

    .section__top-activities{
      .top-activities-block{
        ul{
          .top-activities__item{
            .top-activities__link{
              .top-activities__text{
                .icon{
                  background: url("../../../images/ics/arrow-right-white.svg") no-repeat center;
                  background-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, .4);
  z-index: -1;
  opacity: 0;
  transition: .5s ease;
  &.show {
    z-index: 999;
    opacity: 1;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
  &.show {
    display: block;
  }
}
