.section__top-activities{
  padding: 50px 0;
  background: #f8f8f8;

  .ui-section-title{
    margin-bottom: 30px;
  }

  .top-activities-block{
    width: 100%;

    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -5px;
      @include break-max(600px){
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
      }

      .top-activities__item{
        margin: 5px;
        width: calc(33.33% - 10px);

        @include break-max($mob){
          width: calc(50% - 10px);
        }

        @include break-max(600px){
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 10px;
        }

        .top-activities__link{
          display: block;
          position: relative;
          overflow: hidden;

          .top-activities__text{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -100px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            min-height: 50px;
            padding: 5px 40px 5px 15px;
            background: #fff;
            color: $gray-font-color;
            line-height: 1.2;
            transition: all 0.2s ease;

            &:before{
              content: '';
              display: block;
              visibility: hidden;
              opacity: 0;
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              top: 0;
              height: 3px;
              background: #000;
              width: 0;
              transition: all 0.3s ease;

              @include break-max($tab){
                //width: 100%;
                //opacity: 1;
                //visibility: visible;
                display: none;
                opacity: 0;
                visibility: hidden;
              }
            }

            @include break-max($tab){
              bottom: 0;
              padding-right: 15px;
            }

            @include break-max($mob){
              justify-content: center;
              text-align: center;
            }

            .icon{
              display: inline-block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: auto;
              right: 10px;
              min-width: 15px;
              width: 15px;
              height: 15px;
              background: url("../../images/ics/arrow-right.svg") no-repeat center;
              background-size: 15px;
              margin: auto 0;

              @include break-max($tab){
                position: static;
                display: block;
                margin-left: 10px;
              }
            }
          }

          &:hover{
            .top-activities__text{
              bottom: 0;

              &:before{
                visibility: visible;
                opacity: 1;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.medical{
    background: #f7f8f9;
  }
  &.education{
    background: #f3edde;
  }
  &.sport{
    background: #2d2d2d;
  }
  &.beauty_and_wellness{
    .top-activities-block ul{
      justify-content: center;
    }
  }
}
