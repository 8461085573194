.irs--round {
  .irs-line {
    background-color: $border-color;
    height: 4px;
  }
  .irs-handle {
    border-color: $base-color;
    background: $base-color;
    box-shadow: none;
    cursor: pointer;
    width: 18px;
    height: 18px;
    top: 28px;
    &:hover,
    &:focus,
    &:active {
      background-color: $base-color;
    }
  }
  .irs-bar {
    background-color: $base-color;
    top: 35px;
    height: 4px;
  }
  .irs-from,
  .irs-to,
  .irs-single {
    background-color: transparent;
    color: $black-color;
    font-weight: 600;
    &:before {
      display: none;
    }
  }
}