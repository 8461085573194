.custom-informer{
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;

  .small-title{
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 40px;
    z-index: 10;
    border-bottom: 1px solid $base-color;

    &:after{
      content: '';
      display: block;
      height: 40px;
      width: 40px;
    }
  }

  .shape{
    position: absolute;
    overflow: hidden;

    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: $secondary-color;
      border-radius: 50%;
    }
  }
}