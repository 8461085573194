.promotion-section {
  padding: 10px 0 35px;

  @media (max-width: $mob) {
    padding: 10px 0;
  }
}

.promotion-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid $border-color;

  @media (max-width: $mob) {
    flex-direction: column;
    padding-bottom: 0;
    border: none;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    position: absolute;

    &-wrapper {
      position: relative;
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 50px;
      overflow: hidden;
      margin-right: 15px;
      flex-shrink: 0;

      &.icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        background: $gray-background-color;
        overflow: visible;

        img {
          width: 50px;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
          left: -5px;
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    letter-spacing: 0;

    @media (max-width: $tab) {
      font-size: 22px;
      line-height: 1.3;
    }
  }

  &__expiration {
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;

    &-date {
      font-weight: 600;
      color: $base-color;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;

    @media (max-width: $mob) {
      flex-direction: column;
      margin: 30px 0 0;
    }
  }

  &__discount {
    display: inline-flex;
    align-items: center;
    height: 35px;
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin-right: 10px;
    padding: 0 60px;
    background: $gray-background-color;

    @media (max-width: $mob) {
      margin: 0 0 10px 0;
    }

    &-value {
      margin-left: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #880000;
    }
  }
}

.promotion-content {
  display: flex;
  padding-top: 35px;

  @media (max-width: $tab) {
    flex-direction: column;
    padding-top: 25px;
  }


  &__info {
    max-width: calc(45% - 50px);
    width: 100%;
    margin-right: 50px;

    @media (max-width: $tab) {
      max-width: 100%;
      margin: 0 0 50px;
    }

    @media (max-width: $mob) {
      max-width: 100%;
      margin: 0;
    }
  }

  &__desc {
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin-bottom: 35px;
    @media (max-width: $mob) {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  &__list {
    &-pre {
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-left: 35px;
    }

    &-service, &-membership {
      display: flex;
      font-size: 14px;
      font-weight: 400;

      &:before {
        display: flex;
        flex-shrink: 0;
        content: '';
        width: 25px;
        height: 25px;
        margin-right: 10px;
        background-size: contain;
      }
    }

    &-service:before {
      background: url('../../images/ics/services.svg') no-repeat center;
    }

    &-membership:before {
      background: url('../../images/ics/membership.svg') no-repeat center;
    }

    &-text {
      padding-top: 5px;
    }

    &-title {
      font-weight: 600;
    }
  }


  &__company {
    position: relative;
    display: flex;
    max-width: 55%;
    width: 100%;
    padding: 20px 25px;
    background: $gray-background-color;

    @media (max-width: $tab) {
      max-width: 100%;
    }

    @media (max-width: $mob) {
      background: none;
      flex-direction: column;
      padding: 0;
    }

    &-title {
      position: absolute;
      display: block;
      top: -15px;
      left: 60px;
      font-size: 30px;
      z-index: 1;

      @media (max-width: $mob) {
        position: static;
        font-size: 26px;
        text-align: center;
        padding: 20px 25px 0 25px;
        order: 2;
        background: $gray-background-color;
      }
    }

    &-left {
      display: flex;
      max-width: 50%;
      width: 100%;
      margin-top: 30px;
      @media (max-width: $mob) {
        max-width: 100%;
        margin: 0;
        order: 3;
        background: $gray-background-color;
        padding: 25px 20px 20px 20px;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 50%;
      width: 100%;

      @media (max-width: $mob) {
        align-items: flex-start;
        max-width: 100%;
        order: 1;
      }
    }

    &-image {
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      position: absolute;

      &-wrapper {
        position: relative;
        width: 50px;
        height: 50px;
        min-width: 50px;
        border-radius: 50px;
        overflow: hidden;
        margin-right: 15px;
        flex-shrink: 0;

        &.icon {
          background: $white-color;
          overflow: visible;

          img {
            width: 45px;
            height: 45px;
            top: 50%;
            transform: translateY(-50%);
            left: -5px;
          }
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;

      a {
        color: $black-color;
      }
    }

    &-category {
      font-size: 12px;
      font-weight: 500;
      color: $base-color;
      margin-bottom: 5px;
    }

    &-reviews {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      text-decoration: underline;
      margin-bottom: 5px;

      &:after {
        display: flex;
        content: '';
        width: 16px;
        height: 16px;
        margin-left: 5px;
        background: url('../../images/ics/reviews-black.svg') no-repeat center;
        background-size: contain;
      }
    }

    &-location-pre {
      display: none;

      @media (max-width: $mob) {
        display: block;
        font-size: 14px;
        font-weight: 300;
        color: $gray-font-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 35px 0 10px 25px;
      }
    }

    &-location-item {
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;

      &:before {
        display: inline-flex;
        flex-shrink: 0;
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background-size: contain;
        background: url('../../images/ics/map-marker.svg') no-repeat center;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .promotion-content__map {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    .mapboxgl-control-container {
      display: none;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin: -40px 0 20px;

      @media (max-width: $mob) {
        display: none;
      }

      .map_popup__header {
        display: none;
        background: $gray-background-color;
        position: absolute;
        height: 40px;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 15px;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: $mob) {
          width: calc(100% - 30px);
        }

      }

      &.fullscreen {
        display: block;
        padding-top: 40px;
        position: fixed;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 600px;
        z-index: 1040;
        margin: 0;

        @media (max-width: $mob) {
          max-width: 100%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 0 15px;
        }

        .map_popup__header {
          display: flex;
        }

        .promotion-content__map {
          width: 600px;
          height: 600px;
          border-radius: 0 !important;
          backface-visibility: visible;
          transform: none;

          @media (max-width: $mob) {
            max-width: 100%;
            width: 100%;
            height: 80vh;
            margin-top: 40px;
          }

          .mapboxgl-control-container {
            display: block;
          }
        }

        .promotion-content__map-popup--open {
          display: none;
        }

        .promotion-content__map-popup--close {
          display: block;
        }
      }
    }

    &-popup--open {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50%;
      cursor: pointer;
    }

    &-popup--close {
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 1099;
      background: url("../../images/ics/close-black.svg") no-repeat center;
      background-size: 16px;
    }

    &-btn {
      display: none;

      @media (max-width: $mob) {
        display: inline-flex;
        align-self: center;
        margin: 20px 0;
        &:after {
          background: url('../../images/ics/show-on-map.svg');
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

.similar-promotions {
  .promotion-block__item {
    margin-bottom: 0;
  }
}