  .map-section{
    position: relative;

    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background: $gray-background-color;
      z-index: -1;
    }

    .custom-informer{
      top: 30px;
      right: 30px;
      writing-mode: vertical-rl;

      .small-title{
        border-left: 2px solid $secondary-color;
        border-bottom: none;
        flex-direction: row-reverse;
        padding: 5px;

        &:after{
          background: url("../../images/ics/show-on-map.svg") no-repeat center;
          margin-bottom: 10px;
        }

        @include break-max(1300px){
          font-size: 16px;
          font-weight: 600;
          width: 100%;
          border-left: none;
          border-bottom: 2px solid $secondary-color;
          justify-content: flex-end;
        }
      }

      @include break-max(1400px){
        right: 0;
      }

      @include break-max(1300px){
        top: 0;
        margin-bottom: 20px;
        position: relative;
        writing-mode: unset;
        padding: 0 15px;
        width: 100%;
      }
    }

    .map-section__wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-top: 50px;
      min-height: 600px;

      @include break-max(1200px){
        padding-top: 0;
        flex-wrap: wrap;
        min-height: 200px;
        margin-left: -15px;
        margin-right: -15px;
      }

      .map-section__item-map{
        position: relative;
        text-align: center;
        width: 560px;
        //z-index: -1;

        @include break-max(1200px){
          width: 100%;
          margin-bottom: -100px;
          overflow: hidden;
        }

        &:before{
          content: '';
          display: block;
          width: 250px;
          height: 250px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: $secondary-color;
          border-radius: 50%;
          opacity: 0.6;

          @include break-max(1200px){
            display: none;
          }
        }

        > div{
          margin: 0 auto;
          display: inline-block;
          height: 500px;
          width: 500px;
          min-height: 500px;
          min-width: 500px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -khtml-border-radius: 50%;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          position: relative;
          overflow: hidden;


          @include break-max(1200px){
            width: 100%;
            min-width: 100%;
            height: 300px;
            min-height: 300px;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            -khtml-border-radius: 0;
            -webkit-mask-image: none;
          }

          .mapboxgl-map{
            overflow: visible;
            height: 500px;

            @include break-max($tab){
              height: 300px;
            }

            .mapboxgl-canvas{
              height: 500px !important;

              @include break-max(1200px){
                width: 100%;
                min-width: 100%;
                height: 300px !important;
                min-height: 300px;
              }
            }
          }
        }
      }

      .map-section__item-list{
        width: calc(100% - 500px);
        padding: 0 0 0 25px;
        position: relative;

        @include break-max(1200px){
          padding: 0;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }

        @include break-max($mob){
          max-width: 320px;
        }

        .app-top-company-map-company-list-container{
          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include break-max(1200px){
              margin-left: -5px;
              margin-right: -5px;
            }

            > div{
              width: 50%;

              @include break-max(1200px){
                padding: 5px;
                display: none;

                &:nth-child(1), &:nth-child(2){
                  display: block;
                }
              }

              @include break-max($mob){
                width: 100%;

                &:nth-child(2){
                  display: none;
                }
              }

              .company{
                &__header{
                  padding-top: 0;
                  text-align: center;
                }

                &__logo{
                  margin: -25px auto 15px auto;
                  border-radius: 50%;
                  background: $gray-background-color;
                  position: absolute;
                  left: 0px;
                  right: 0;
                  top: 0;

                  img{
                    position: relative;
                  }

                  &.icon img {
                    height: 60px;
                    width: 60px;
                    top: -10px;
                    left: -5px;
                  }
                }

                &__info{
                  padding-top: 70px;
                  &.icon {
                    padding-top: 50px;
                  }
                }

                &__title{
                  font-weight: 500;
                }
                &__category{
                  font-weight: 600;
                }
                &__data {
                  justify-content: center;
                  margin-top: 0;
                }
                &__location {
                  justify-content: center;
                  border: none;
                  padding-bottom: 0;
                  &-address {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }

      .map-section__search-info{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 40px;

        @include break-max(1200px){
          flex-direction: column-reverse;
          padding-left: 40px;
          padding-right: 40px;
        }

        .dr-btn{
          margin-right: 20px;
          position: relative;
          height: auto;
          min-height: 40px;
          padding-top: 10px;
          padding-bottom: 10px;

          .fa-map-marker-alt{
            height: 24px;
            margin-right: 10px;
          }

          &:after{
            content: '';
            display: block;
            height: 32px;
            width: 57px;
            position: absolute;
            bottom: -32px;
            right: -40px;
            background: url("../../images/ics/arrow-map.svg") no-repeat center;

            @include break-max(1200px){
              bottom: auto;
              top: -5px;
              right: -60px;
              transform: rotate(-32deg);
            }
          }

          @include break-max($tab){
            margin-right: 0;
            max-width: 300px;
          }
        }

        .map-section__search-info-txt{
          color: $gray-font-color;
          font-size: 14px;

          @include break-max(1200px){
            margin-bottom: 20px;
            text-align: center;
          }

          .map-section__search-info-resault{
            font-weight: 600;
            font-size: 16px;
            color: $base-color;
          }
        }
      }
    }
  }