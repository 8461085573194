.reviews-carousel-section{
  position: relative;
  min-height: 250px;

  .custom-informer{
    padding: 0 30px 0 60px;
    position: relative;

    .small-title{
      padding: 0 15px 0 0;

      &:before{
        content: '';
        display: block;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        background: url("../../images/ics/reviews-md.svg") no-repeat center;
      }
      &:after{
        display: none;
      }
    }

     .shape{
       height: 250px;
       width: 250px;
       top: -75px;
       left: 0;

       &:before{
         top: 0;
         left: -50%;
       }
     }

    @include break-max($mob){
      display: none;
    }
  }
}

.reviews-carousel-wrapper{
  position: relative;
  max-width: 740px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0;

  @include break-max($mob){
    max-width: 400px;
  }

  .ui-cursive{
    font-size: 32px;
    position: absolute;
    right: 30px;
    top: 65px;
    letter-spacing: 2px;
    z-index: 10;

    @include break-max($mob){
      font-size: 24px;
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      position: static;
      margin-bottom: 20px;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
.reviews-carousel{
  .reviews-carousel__item{
    padding: 50px 0 25px 40px;

    @include break-max($mob){
      padding: 50px 0 25px 0;
    }

    .reviews-carousel__item-body{
      position: relative;
      border: 1px solid $border-color;
      padding: 30px 30px 30px 136px;

      @include break-max($mob){
        padding: 0 20px 50px 20px;
        text-align: center;
      }

      &:before,&:after{
        content: '';
        display: block;
        position: absolute;
      }

      &:before{
        left: -40px;
        top: 20px;
        height: 80px;
        width: 80px;
        background: $secondary-color;
        border-radius: 50%;

        @include break-max($mob){
          left: 50%;
          top: 35px;
          margin-left: -70px;
        }
      }

      &:after{
        bottom: -25px;
        left: 15px;
        width: 80px;
        height: 56px;
        background: url("../../images/reviews/quote.svg") no-repeat center;

        @include break-max($mob){
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 60px;
          height: 50px;
        }
      }

      .reviews-carousel__item-header{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;

        @include break-max($mob){
          justify-content: center;
          flex-direction: column;
        }

        .reviews-carousel__item-avatar{
          height: 130px;
          width: 130px;
          position: absolute;
          left: -10px;
          top: -50px;

          img{
            height: 130px;
            width: 130px;
            border-radius: 50%;
          }

          @include break-max($mob){
            position: relative;
            top: -35px;
            left: 0;
            margin-bottom: -10px;
          }
        }

        .reviews-carousel__item-client{
          .item-client__name{
            font-weight: 600;
          }
          .item-client__position {
            font-weight: 300;
          }
        }
      }

      .reviews-carousel__item-review {
        font-weight: 300;
      }
    }
  }

  .owl-nav{
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 100%;

    @include break-min($mob + 1){
      width: 266px;
    }

    button{
      z-index: 3;

      span{
        opacity: 0.15;
      }

      &:hover{
        span{
          opacity: 1;
        }
      }
    }
  }

  .owl-dots{
    padding: 0 55px;

    @include break-min($mob + 1) {
      text-align: right;
    }

    @include break-max($mob) {
      padding-top: 20px;
    }

    .owl-dot {
      margin: 5px 15px;
    }
  }
}