.search-page {
  background-color: $gray-background-color;
}

.search-page.search-page--map-view{
  
  .show-header-on-map-view-container{
    position: relative;
  }

  .show-header-on-map-view{
    display: none;
  }

  .search__header {
    padding: 10px 0;

    &:before {
      display: none;
    }

    .search__header-wrapper{
      align-items: center;

      .search__header-item {
        &.search__header-show-filters{
          display: block;
          margin-left: auto;
          margin-right: 30px;
        }
      }
    }

    @include break-max($tab){
      padding-top: 15px;

      .search__header-wrapper{
        padding-left: 0;
      }
    }
  }

  .sort-bar{
    @include break-max($mob){
      margin-bottom: 15px;
    }
  }

  .sort-bar__view:before{
    background: url("../../../images/ics/list-view.svg") no-repeat center;
  }
}

.modal-open{
  .search-page.search-page--map-view{
    .show-header-on-map-view{
      z-index: 100;
    }
  }
}

.search-page {

  .map-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    height: calc(100% - 179px);
    background-color: $gray-background-color;
    top: 179px;
    overflow: auto;
    @include transition;

    @include break-max($tab) {
      top: 184px;
      height: calc(100% - 184px);
    }

    @include break-max($mob) {
      position: relative;
      height: auto;
      top: 0;
    }

    &.in {
      top: 248px;
      height: calc(100% - 248px);

      @include break-max($tab) {
        top: 254px;
        height: calc(100% - 254px);
      }
    }

    // NHS Geowidget
    &--nhs {
      height: calc(100% - 104px) !important;
      top: 68px !important;

      @media (max-width: $mob) {
        top: -20px !important;
      }

      .search-label-nhs {
        margin: 30px 0 15px;
        padding: 15px 30px;
        font-size: 16px;
        font-weight: 400;
        background: #fff;

        @media (max-width: $tab) {
          margin: 30px 0 50px;
          padding: 10px 20px;
          font-size: 14px;
        }

        @media (max-width: $mob) {
          margin: 30px 0 10px;
        }
      }

      .map-view__list {
        padding-top: 0 !important;

        .search-result-map {
          @media (max-width: $mob) {
            flex-direction: column;
          }
        }
      }

      .app-map {
        height: calc(100% - 104px) !important;
        bottom: 36px !important;

        @media (max-width: $mob) {
          bottom: 0 !important;
        }
      }
    }


    .column {

      &.map-view__map {
        width: calc(100% - 580px);

        @include break-max($tab) {
          width: calc(100% - 460px);
        }

        @include break-max($mob) {
          width: calc(100% + 30px);
          margin-left: -15px;
        }

        .app-map {
          position: fixed;
          width: calc(100% - 598px);
          bottom: 0;
          left: 0;
          height: calc(100% - 179px);
          @include transition;

          .mapboxgl-canvas {
            height: 100% !important;
            width: 100% !important;
          }

          @include break-max($tab) {
            width: calc(100% - 460px);
            height: calc(100% - 184px);
          }

          @include break-max($mob) {
            width: 100%;
            height: 400px !important;
            position: relative;
            margin-bottom: -170px;
            z-index: 0;
          }

          &.in {
            height: calc(100% - 248px);

            @include break-max($tab) {
              height: calc(100% - 256px);
            }
          }
        }
      }

      &.map-view__list {
        width: 580px;
        padding: 0 10px;
        background: $gray-background-color;

        @include break-max($tab) {
          padding-top: 30px;
          width: 460px;
        }

        @include break-max($mob) {
          padding: 0 5px;
          width: 100%;
          background: transparent;
          position: relative;
          .search-result-map {
            display: flex;
            align-items: center;
            overflow: hidden;
            overflow-x: auto;
          }

        }
      }

      &.search-result.map-view__list{
        display: block;
        margin: 0;
      }
    }

    &__list {
      .search-result-map {
        &__container {
          @media (max-width: $mob) {
            margin: 0;

            > div {
              padding-top: 30px;
              scroll-snap-type: x mandatory;
              overflow-x: scroll;
              display: flex;
              -webkit-overflow-scrolling: touch;

              > div {
                scroll-snap-align: center;
              }
            }
          }
        }
      }


      .search-result{
        margin: 0;
        display: block;

        > div{
          width: 100%;
        }

        .company {
          margin: 0 0 10px;
          width: 100%;
          flex: 100%;

          @media (max-width: $tab) {
            margin: 0 0 35px;
          }

          @media (max-width: $mob) {
            margin: 0 5px;
            min-width: 300px;
            max-width: 300px;
          }

          &__header {

          }

          &__location {
            &-show {
              font-size: 12px;
              color: $gray-font-color;

              @media (max-width: $mob) {
                display: none;
              }
            }

            .toggle {
              display: inline-block;
              width: 16px;
              height: 16px;
              content: "";
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url(../../../images/ics/arrow-down.svg);
              transition: all 0.25s ease;

              &[aria-expanded="false"] {
                transform: rotate(0deg);
              }

              &[aria-expanded="true"] {
                transform: rotate(-180deg);
              }
            }

            .location__list {
              position: absolute;
              background: $gray-background-color;
              left: 0;
              top: 100%;
              width: 100%;
              z-index: 1;

              &-wrapper {
                padding: 20px 30px 20px 55px;
                width: 100%;
              }

              &-item {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 20px;

                &:last-child {
                  margin-bottom: 0;
                }

                .company__location-address {
                  &:before {
                    display: none;
                  }
                }

                .choice-company-location {
                  cursor: pointer;
                }
              }
            }

            a.company__location-address {
              text-decoration: underline;
              display: none;

              @media (max-width: $mob) {
                display: block;
                margin-right: 0;
              }
            }
          }

          .service__list {
            .service__item {
              .service__body {
                @media (max-width: $tab) {
                  padding: 15px;
                }

                &-modal {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex: 1;
                }

                .service__title {
                  width: 55%;
                }
              }
            }

            .service__more {
              padding-left: 65px;
              margin-top: auto;
              padding-top: 10px;

              @media (max-width: $tab) {
                text-align: center;
                padding-left: 0;
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }


      .next-page-btn {
        display: block;
        margin: 0 auto;
        font-size: 0;
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: 50px;
        background-size: 50px;
        background-image: url(../../../images/ics/dr-preloader.svg);
      }
    }

    #map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 50;

      .leaflet-popup {
        .leaflet-popup-close-button {
          display: none;
        }

        .leaflet-popup-content-wrapper {
          border-radius: 6px;
        }
      }

      .leaflet-popup-content {
        .company {
          box-shadow: none;
          padding: 5px;
        }

        .company-title {
          font-weight: 600;
          font-size: 18px;
        }

        .book-now {
          text-align: right;

          .dr-btn {
            color: $white-color;
            margin-top: 5px;
          }
        }

        .adress {
          color: $link-color;
        }
      }
    }
  }

  &-widget {
    .map-view {
      top: 137px;
      height: calc(100% - 137px);

      @include break-max($tab) {
        top: 142px;
        height: calc(100% - 142px);
      }

      @include break-max($mob) {
        position: relative;
        height: auto;
        top: 20px;
      }

      .column.map-view__map .app-map {
        height: calc(100% - 137px);

        @include break-max($mob) {
          .app-map {
            width: 100%;
            height: 400px !important;
            position: relative;
            margin-bottom: -170px;
            z-index: 0;
          }
        }

        &.in {
          height: calc(100% - 206px);

          @include break-max($tab) {
            height: calc(100% - 212px);
          }
        }
      }

      &.in {
        top: 206px;
        height: calc(100% - 206px);

        @include break-max($tab) {
          top: 212px;
          height: calc(100% - 212px);
        }
      }

      .company__category {
        display: none;
      }

      .company__data-reviews {
        display: none;
      }

      .nothing-found__text__all_categories {
        display: none;
      }
    }
  }
  &__map-and-footer{
    .map-view {
      height: calc(100% - 200px);

      @include break-max($tab) {
        height: calc(100% - 216px);
      }

      @include break-max($mob) {
        top: 20px;
      }

      .column.map-view__map .app-map {
        height: calc(100% - 200px);
        bottom: 63px;

        @include break-max($tab) {
          height: calc(100% - 216px);
          bottom: 76px;
        }

        @include break-max($mob) {
          .app-map {
            margin-bottom: -170px;
          }
        }

        &.in {
          height: calc(100% - 206px);

          @include break-max($tab) {
            height: calc(100% - 212px);
          }
        }
      }

      &.in {
        top: 206px;
        height: calc(100% - 206px);

        @include break-max($tab) {
          top: 212px;
          height: calc(100% - 212px);
        }
      }
    }
  }
}

#app_info_container{
  > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include break-max($mob){
      align-items: center;
    }

    .company{
      width: 100%;
      flex: 0 0 100%;
      margin: 0 0 20px 0;

      @include break-max($mob){
        max-width: 500px;
      }
    }
  }
}

.company__location-description{
  display: none;
  width: 100%;
  height: 0;
  white-space: normal;
  font-size: 12px;
  color: #0B3052;
  opacity: 0.6;
  overflow: hidden;
  visibility: hidden;
}
.search-page{
  &.search-page--map-view{
    &.search-page--map-view{//DO NOT TOUCH THIS CODE of fix for this page https://directory.mykola.d.simplybook.ovh/en/map-search/?zoom=3&query=mtest&bounds=52.68154802454552%2C-45.2694588225321%2C23.83922956122585%2C54.805475918270076
      .company{
        // header accordion
        &__location{
          flex-wrap: wrap;
          align-items: flex-start;

          .company__location-address{
            flex: 1;
            max-width: 95%;
            overflow: hidden;

            .company__location-address-row{
              display: block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .company__location-address-chosen + .company__location-description{
            width: 100%;
            overflow: hidden;
          }
        }
      }

      // body accordion
      .location__list-wrapper{
        .location__list-item{
          position: relative;
          border-bottom: 1px solid $border-color;
          margin: 0;
          padding-top: 18px;
          padding-bottom: 18px;

          &:first-child{
            padding-top: 0;

            .company__location-show{
              top: 2px;
            }
          }

          &:last-child{
            border-bottom: none;
            padding-bottom: 0;
          }

          .company__location-address{
            width: 100%;
            flex: unset;
            max-width: 100%;
          }

          .company__location-address-row{
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100% - 100px);

            @include break-max($mob){
              max-width: 100%;
              white-space: normal;
            }
          }

          .company__location-show{
            position: absolute;
            top: 18px;
            right: 1px;

            @include break-max($mob){
              position: static;
              margin: 4px 0 0 auto;
            }
          }
        }
      }

      .company__location-description{
        display: block;
        visibility: visible;
        height: auto;
      }

      .search-result{
        .company__location{
          @include break-max($mob){
            padding: 10px 16px;
          }

          .location__list-wrapper {
            @include break-max($mob){
              padding: 20px 15px 20px 15px;
            }
          }
        }
      }
    }
  }
}


@keyframes fadeOutDown {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99%{
    z-index: 1003;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: hidden;
    z-index: 0;
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}