//lib
// @import "~mapbox-gl";
@import "~maplibre-gl";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/bootstrap-grid";
@import "~ion-rangeslider/css/ion.rangeSlider.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css";
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~magnific-popup/dist/magnific-popup.css';
//

//base
@import "base/base";
//

//components
@import "components/category-nav-list";
@import "components/modal";
@import "components/breadcrumbs";
@import "components/dropdown";
@import "components/confirm-location-dialog";
@import "components/confirm-delete-review-dialog";
@import "components/range-slider";
@import "components/items";
@import "components/pagination";
@import "components/top-activities-list";
@import "components/owl-dots-nav";
@import "components/custom-title-informer";
@import "components/advantage-block";
@import "components/reviews-carousel";
@import "components/cta-block";
@import "components/easy-use-block";
@import "components/top-companies";
@import "components/promotion-block";
@import "components/map-and-service-block";
@import "components/main-service-search";
@import "components/main-location-selector";
@import "components/flags";
@import "components/key-benefits";
@import "components/raiting-block";
@import "components/404-page";
@import "components/rating-stars";
@import 'components/magnific-gallery';
@import "components/go-to-page";


//modules
@import "modules/header";
@import "modules/page";
@import "modules/main-block";
@import "modules/footer";
@import "components/iubenda-banner";


.marker {
  border: none;
  cursor: pointer;
  height: 36px;
  width: 27px;
  background-image: url(../images/ics/marker.svg);
  &.active {
    background-image: url(../images/ics/marker-dark.svg);
    background-size: 40px;
    height: 53px;
    width: 41px;
    z-index: 2;
  }
}

.cluster {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;

  &.sz-1 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.5);
  }
  &.sz-2 {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.6);
  }
  &.sz-3 {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.7);
  }
  &.sz-4 {
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.8);
  }
}

.g-recaptcha-center {
  display: flex;
  justify-content: center;
}
