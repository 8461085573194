.dr-btn {
  display: inline-flex;
  align-items: center;
  padding: 0px 20px;
  height: 40px;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
  color: $black-color;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @include transition;
  &--dark {
    background-color: $black-color;
    color: $white-color;
    &:hover {
      box-shadow: inset 0 0 0 2px $white-color;
    }
  }
  &--primary {
    background-color: $base-color;
    color: $white-color;
    &:hover {
      background: darken($base-color, 10%);
    }
  }
  &--secondary {
    background-color: $secondary-color;
    &:hover {
      background: darken($secondary-color, 10%);
    }
  }
  &--secondary-alt {
    background-color: $secondary-alt-color;
    &:hover {
      background: darken($secondary-alt-color, 10%);
    }
  }
  &--grey {
    background-color: $gray-background-color;
    &:hover {
      background: darken($gray-background-color, 10%);
    }
  }
  &--icon-left {
    &:before {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &--icon-right {
    &:after {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-left: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &--arrow-right {
    position: relative;
    padding: 0 30px;
    &:after {
      display: inline-flex;
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      top: 12px;
      right: 10px;
      background-image: url(../../../images/ics/arrow-right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      @include transition;
    }
      &:hover {
        &:after {
          opacity: 1;
        }
      }

    @include break-max($tab){
      &:after{
        opacity: 1;
      }
    }
  }
  &--arrow-right-move {
    &:after {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-left: -16px;
      background-image: url(../../../images/ics/arrow-right-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      @include transition;

      @media (max-width: $tab) {
        margin-left: 10px;
        opacity: 1;
      }
    }
    &:hover {
      &:after {
        margin-left: 10px;
        opacity: 1;
      }
    }
  }

  &--arrow-left-move {
    &:before {
      display: inline-flex;
      content: '';
      width: 16px;
      height: 16px;
      margin-right: -16px;
      background-image: url(../../../images/ics/arrow-right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transform: scale(-1);
      @include transition;

      @media (max-width: $tab) {
        margin-right: 10px;
        opacity: 1;
      }
    }
    &:hover {
      &:before {
        margin-right: 10px;
        opacity: 1;
      }
    }
  }
  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    outline: none;
  }
}

