.company {
  position: relative;
  background: $white;
  border: 1px solid $border-color;
  padding: 0;
  margin-bottom: 40px;
  transition: .3s ease;

  &:hover {
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: $tab) {
    margin: 0 0 35px;
  }

  @media (max-width: $mob) {
    width: 100%;
    flex: 0 0 100%;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.05);
  }

  &__promotions-link {
    position: absolute;
    display: block;
    right: 10px;
    top: 0;
    width: 40px;
    height: 45px;
    border-radius: 0 0 50px 50px;
    background: $secondary-color url('../../images/ics/discount.svg') no-repeat center;
    background-size: 30px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px;

    @media (max-width: $tab) {
      flex-direction: column;
      text-align: center;
      min-height: auto;
    }
  }

  &__logo {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 25px;
    background: $gray-background-color;

    @media (max-width: $tab) {
      position: absolute;
      top: -20px;
    }

    &.icon {
      width: 60px;
      height: 60px;
      overflow: visible;

      img {
        width: 60px;
        height: auto;
        top: -10px;
        left: -10px;
      }
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      position: absolute;
    }

  }

  .company__info {
    width: 100%;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 10px;

    a {
      color: $black-color;
    }
  }

  &__category {
    font-size: 12px;
    font-weight: 500;
    color: $base-color;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: $tab) {
      font-size: 14px;
    }

    a {
      display: inline-block;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__data {
    display: flex;
    align-items: center;
    margin-top: 8px;

    @media (max-width: $tab) {
      justify-content: center;
    }

    &-reviews {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      margin-left: 20px;

      &:before {
        display: flex;
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background: url('../../images/ics/reviews-black.svg') no-repeat center;
        background-size: contain;
      }
    }
  }

  &__location {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    @media (max-width: $mob) {
      justify-content: center;
    }

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    &-address {
      color: $black-color;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: auto;

      @media (max-width: $mob) {
        display: none;
      }
    }

    a.company__location-address {
      text-decoration: underline;
      display: none;

      @media (max-width: $mob) {
        display: block;
        margin-right: 0;
      }
    }
  }

  .company__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .company__show-map {
      color: $gray-font-color;
      font-size: 14px;
      text-decoration: underline;
      font-weight: 400;
    }

    .dr-btn--icon-right:after {
      background: url("../../images/ics/arrow-right.svg") no-repeat center;
    }
  }
}

.promotion {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  margin: 5px;
  background: #f5f2eb;
  box-shadow: none;
  min-width: 0;
  @include transition;

  &:hover {
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);

    .promotion__btn-apply {
      background: #ede4cf;

      &:after {
        opacity: 1;
      }
    }
  }

  @media (max-width: $tab) {
    margin: 0 5px 35px;

    .promotion__btn-apply {
      background: #ede4cf;

      &:after {
        opacity: 1;
      }
    }
  }

  @media (max-width: $mob) {
    width: 100%;
  }

  &__info {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    @media (max-width: $tab) {
      margin-top: 55px;
      text-align: center;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 15px 25px 15px 15px;

      @media (max-width: $tab) {
        flex-direction: column;
      }

      @media (max-width: $mob) {
        padding: 15px;
      }
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    position: absolute;

    &-wrapper {
      position: relative;
      flex-shrink: 0;
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 50px;
      overflow: hidden;
      background: $gray-background-color;

      @media (max-width: $tab) {
        position: absolute;
        top: -20px;
        margin: 0;
      }

      &.icon {
        width: 60px;
        height: 60px;
        overflow: visible;

        img {
          width: 50px;
          height: auto;
          top: 0;
          left: -5px;
        }
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @media (max-width: $tab) {
      margin-bottom: 5px;
    }

    &-link {
      color: $black-color;
    }
  }

  &__category {
    font-size: 12px;
    font-weight: 500;
    color: $base-color;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;

    @media (max-width: $tab) {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &-link {
      display: inline-block;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__expiration {
    font-size: 12px;
    font-weight: 300;
    color: $gray-font-color;
    text-align: left;

    &-date {
      font-weight: 600;
      color: $base-color;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $tab) {
      justify-content: center;
      margin-top: 10px;
    }

    &-desc {
      max-width: 80%;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;

      @media (max-width: $tab) {
        max-width: 100%;
        text-align: center;
      }
    }

    &-btn {
      margin-left: auto;
      font-weight: 300;
      color: $gray-font-color;
      text-decoration: underline;

      @media (max-width: $tab) {
        display: none;
      }
    }
  }

  &__data {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-top: auto;
    border-top: 1px solid $white-color;
    border-bottom: 1px solid $white-color;

    @media (max-width: $tab) {
      align-items: flex-start;
      flex-direction: column;
      padding: 15px 20px;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    margin-right: 15px;
    max-width: calc(50% - 15px);
    flex-shrink: 0;

    @media (max-width: $tab) {
      max-width: 100%;
      margin-bottom: 10px;
    }

    &-icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    &-link {
      color: $black-color;
      font-size: 14px;
      font-weight: 300;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    max-width: 50%;
    width: 100%;

    &-multiple {
      position: relative;
      max-width: 100%;
      min-width: 0;
      width: 100%;

      .promotion__location-address {
        max-width: 60%;
      }
    }

    @media (max-width: $tab) {
      max-width: 100%;
      text-decoration: underline;
    }

    &-icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    &-address {
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include transition;

      &:hover {
        color: $black-color;
      }
    }

    &-toggle {
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      text-decoration: underline;
      margin-left: auto;
    }

    &-list {
      position: absolute;
      flex-direction: column;
      background: #fff;
      left: 0;
      top: 30px;
      padding: 20px 19px;
      width: 100%;
      z-index: 1;

      &.show {
        display: flex;
      }
    }

    &-item {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;
      color: $gray-font-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .promotion__location-address {
        max-width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__discount {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin-left: 20px;

    &-value {
      margin-left: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #880000;
    }
  }

  &__btn-apply {
    position: relative;
    margin-top: auto;

    &:hover {
      background: #ede4cf;
    }
  }
}