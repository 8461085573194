.dr-pagination {
  width: 100%;
  margin-top: 30px;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    cursor: pointer;

    &.active {
      .dr-pagination__link {
        font-weight: 600;
        color: $black-color;
        border-bottom-color: $base-color;
      }
    }

    &.num:hover {
      .dr-pagination__link {
        color: $black-color;
        border-bottom-color: rgba($base-color, 0.5);
      }
    }

    &.more-dots {
      width: 25px;
      border: none;
      color: $gray-font-color;
      text-align: center;
    }

    &.action {
      .dr-pagination__link {
        padding-bottom: 0;
        border: none;

        &:hover:not(.disabled) {
          &:before {
            opacity: 1;
          }
        }

        &:before {
          display: inline-flex;
          content: "";
          width: 23px;
          height: 23px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background: url('../../images/ics/pagination/arrow-pagination.svg');
          opacity: 0.3;
          @include transition;
        }
      }

      &.prev {
        .dr-pagination__link {
          &:before {
            transform: scale(-1);
          }
        }
      }
    }
  }

  &__link {
    display: block;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    padding: 0 15px 5px;
    color: $gray-font-color;
    border-bottom: 3px solid $secondary-color;
    @include transition;

    &.disabled {
      &:before {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}