.mfp-figure:after {
  box-shadow: none;
  background: $white-color;
}

.mfp-img {
  padding-bottom: 0 !important;
  max-height: 550px !important;
}

.mfp-bottom-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $white-color;
  height: 80px;
  margin-top: -40px;
  padding: 0 25px;
  top: 0;
  bottom: auto;
}

.mfp-title {
  font-size: 16px;
  font-weight: 500;
  color: $black-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95%;
}

.mfp-counter {
  position: static;
  font-weight: 300;
  color: $gray-font-color;
}

.mfp-image-holder .mfp-close {
  width: 20px;
  height: 20px;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  font-size: 0;
  opacity: 1;
  background: url('../../images/ics/close-black.svg') no-repeat center;
  background-size: 16px;
}

.mfp-s-loading .mfp-arrow {
  display: none;
}

button.mfp-arrow {
  width: 40px;
  height: 40px;
  background: url('../../images/ics/arrow-gallery.svg') no-repeat center;
  background-size: contain;
  &:before, &:after {
    display: none;
  }
}

.mfp-arrow-left {
  left: -45px;
  transform: scale(-1);

  @media (max-width: $tab) {
    left: 40%;
    bottom: -60px;
    top: auto;
    transform: translateX(50%) scale(-1);
  }
}

.mfp-arrow-right {
  right: -45px;

  @media (max-width: $tab) {
    right: 40%;
    bottom: -60px;
    top: auto;
    transform: translateX(50%);
  }
}
