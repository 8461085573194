body.directory-widget {
  background-color: $gray-background-color;
  .rating-stars {
    display: none;
  }
  .app-company-list {
    .company {
      .company__info {
        .company__data {
          display: none;
        }
      }
    }
  }
}

.search-page-widget {
  .search__header-wrapper {
    display: none !important;
  }
  .map-view--content-header {
    display: none;
  }
}