@media (max-width: $mob) {
  .header-menu-in {
    overflow: hidden;
    height: 100%;
  }
}

.header {
  padding: 10px 0;
  background-color: #fff;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__logo {
    display: block;
    width: 100%;
    max-width: 110px;
    @include transition;

    @include break-max($mob) {
      max-width: 100px;
    }

    &:hover {
      opacity: .7;
    }

    img {
      max-width: 200px;
    }

    &--widget {
      height: 60px;

      img {
        display :none;
      }
    }

    &--nhs {
      display: flex;
      align-items: center;
      max-width: 75%;

      &:hover {
        opacity: 1;
      }

      .header__logo-text {
        font-size: 20px;
        font-weight: 600;

        @media (max-width: $mob) {
          display: none;
        }
      }

      img {
        max-width: 120px;
        margin-right: 40px;
      }
    }
  }

  &__search {
    margin-left: auto;

    @media (max-width: $mob) {
      order: 3;
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }

    &-form {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 20px;

      @media (max-width: $mob) {
        flex-direction: column;
        margin-right: 0;
      }

      .select-block {
        width: 160px;
        height: 35px;
        margin: 0;
      }

      @media (max-width: $mob) {

        .select-block {
          width: 100%;
          margin-bottom: 5px;

          .form-search__category-selected {
            font-weight: 600;
            font-size: 18px;
          }
        }

        .input-block {
          input {
            width: calc(100% - 20px);
            border: 1px solid $border-color !important;
          }
        }
      }

      select, input {
        height: 35px;
      }

      &--widget {
        .input-block {
          input {
            border-left: 1px solid $border-color !important;
          }
        }

        .form-search__submit {
          @media (max-width: $mob) {
            top: -3px !important;
            right: 0 !important;
            margin: 0 !important;
          }
        }
      }
    }

    .form-search {
      &__submit {
        padding: 0 20px;
        position: absolute;
        height: 40px;
        border-radius: 50px;
        right: -20px;
        margin: 0;
        background: $secondary-color url('../../images/ics/search-submit-base.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: $mob) {
          top: 38px;
          right: 0;
          margin: 0;
        }
      }

      &__select {
        height: 35px;
        @include break-max($tab) {
          border-right: none !important;
        }

        @include break-max($mob) {
          border-right: 1px solid $border-color !important;
        }
      }
    }
  }

  .open-header-search-form {
    cursor: pointer;
    display: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: $secondary-color url('../../images/ics/search-submit-base.svg');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    outline: none;

    &.active {
      background: $secondary-color url('../../images/ics/close-black.svg') no-repeat center;
      background-size: 14px;
    }
  }

  .new-select-view-btn-bar {
    display: none;
  }

  &__button {
    @include break-max($tab) {
      display: none;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 30px;

    @media (max-width: $tab) {
      margin-left: 5px;
      margin-right: 0;
    }

    @media (max-width: $mob) {
      margin-left: 10px;
      margin-right: 0;
      max-width: 50%;
    }
  }

  &__menu {
    position: relative;

    .header__menu-button {
      padding: 0 15px 0 0;
      margin-right: 15px;
      min-width: 32px;

      @include break-max($mob) {
        margin-left: 10px;
        margin-right: 10px;
      }

      &:before {
        content: '';
        position: relative;
        display: block;
        width: 19px;
        height: 19px;
        background: url("../../images/ics/bars-regular.svg") no-repeat center;
        background-size: 19px;
      }
    }

    .header__nav-controll-bar {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: flex-start;
      padding: 0 60px 0 60px;
      cursor: pointer;

      @include break-max($mob) {
        background: $gray-background-color;
        border: 1px solid $border-color;
        box-sizing: border-box;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -12px;
        display: block;
        width: 24px;
        height: 24px;
      }

      &:before {
        height: 22px;
        width: 22px;
        margin-top: -11px;
        left: 25px;
        background: url("../../images/ics/bars-regular.svg") no-repeat center;

        @include break-min($mob + 1) {
          height: 14px;
          width: 14px;
          margin-top: -7px;
          left: 30px;
          background: url("../../images/ics/close-black.svg") no-repeat center;
          background-size: 28px;
        }
      }

      &:after {
        right: 22px;
        display: none;
        background: url("../../images/ics/close-modal.svg") no-repeat center;

        @include break-max($mob) {
          display: block;
        }
      }

      .txt {
        font-weight: 600;
        font-size: 16px;

        @include break-min($mob + 1) {
          font-size: 14px;
        }
      }
    }

    .header__nav-wrapper {
      border: 1px solid $border-color;
      background: $white-color;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: auto;
      //min-height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease;
      z-index: 9999;

      &.is-open {
        visibility: visible;
        opacity: 1;
        top: 0;
      }

      @include break-min($mob + 1) {
        position: absolute;
        top: 40px;
        left: 0;
        padding-top: 10px;
        max-width: 260px;
        min-width: 260px;
        height: 100%;
        min-height: 250px;
        box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.2);

        &.is-open {
          visibility: visible;
          opacity: 1;
          top: -10px;
        }
      }

      .header__nav {
        position: relative;
        padding: 10px 0;
        height: calc(100% - 40px);
        overflow-y: auto;

        @include break-max($mob) {
          padding: 20px 0;
        }

        .header__nav-item {
          .header__nav-link {
            color: $gray-font-color;
            padding: 0 25px 0 40px;
            font-size: 16px;
            display: inline-flex;
            width: 100%;
            min-height: 40px;
            align-items: center;
            justify-content: flex-start;
            @include transition;

            &:hover {
              color: $black-color;
              background: $gray-background-color;
            }

            @include break-min($mob + 1) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .header__menu-cover {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 999;

      &.in {
        @include break-max($mob) {
          display: block;
        }
      }
    }

  }

  &.new-select-view {

    .header__wrapper {
      .header__search {
        .header__search-form.form-search {
          &.in {
            display: flex;
          }
        }
      }
    }

    @include break-max($mob) {
      padding: 5px 0;
      .header__wrapper {

        .header__location {
          .location-select__button {
            .location-select__label {
              padding: 0 10px;
              margin-right: 10px;

              > span {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 76px;
              }
            }

          }
        }

        .open-header-search-form {
          display: block;
        }

        .header__search {
          margin-top: 0;

          .header__search-form.form-search {
            display: none;
            padding: 20px 8px 15px 8px;

            &.in {
              display: block;
            }

            .input-block {
              input {
                width: 100%;
              }
            }

            .form-search__submit {
              display: none;
            }

            .new-select-view-btn-bar {
              width: 100%;
              margin-top: 5px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: relative;

              //.close-header-search-form{
              //  padding-left: 0;
              //  color: $gray-font-color;
              //  text-decoration: underline;
              //}

              .form-search__submit {
                display: inline-flex;
                border-radius: 0;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: relative;
                background: $base-color;
              }
            }
          }
        }
      }
    }
  }

  &__lang {
    position: relative;
    margin-left: auto;

    .header__lang-button{
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      min-height: 38px;
      padding: 5px;

      .flag{
        width: 22px;
        height: 15px;
      }
    }

    @media (max-width: $mob) {
      &.opened {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .header__lang-button {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 60px 0 20px;
          height: 40px;
          background: $gray-background-color;
          border: 1px solid $border-color;
          z-index: 1040;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 22px;
            margin-top: -12px;
            display: block;
            width: 24px;
            height: 24px;
            background: url(../../images/ics/close-modal.svg) no-repeat center;
          }
        }

        .header__lang-title {
          display: block;
          margin-left: 10px;
          color: $black;
          font-size: 16px;
          font-weight: 600;
          margin-top: 2px;
        }
        .header__lang-list {
          top: 40px;
          left: 0;
          transform: none;
          padding: 20px 10px;
          margin: 0;
          background: $white;
          box-shadow: 0 5px 7px 1px rgba(0, 0, 0, 0.15);

          @include break-max($mob){
            padding: 20px;
          }
        }
      }
    }

    &-list {
      position: absolute;
      min-width: 250px;
      width: 100%;
      //display: flex;
      //flex-wrap: wrap;
      padding: 20px;
      margin: 0 -10px;
      left: 50%;
      transform: translateX(-50%);
      top: 35px;
      z-index: 1040;
      background: $gray-background-color;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

      &.show {
        display: flex;
      }

      .header__lang-list-wrap{
        //display: flex;
        //flex-wrap: wrap;
        width: 100%;
        max-height: 320px;
        overflow-y: auto;
      }
    }

    &-title {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      //max-width: calc(50% - 20px);
      width: 100%;
      //margin: 4px 10px;
      padding: 5px 0;

      @include break-max($tab){
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &--selected {
        .header__lang-name {
          font-weight: 600;
          color: $black;
        }
      }
    }

    &-flag {
      flex-shrink: 0;
      margin-right: 10px;

      &.flag{
        width: 18px;
        height: 12px;
      }
    }

    &-name {
      color: $gray-font-color;
      font-size: 14px;
      font-weight: 300;
      margin-top: 2px;
    }
  }
}

.categories-opened {
  .header__search .form-search__select {
    height: 40px;
  }
}
