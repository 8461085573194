.hideBodyScroll{
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}
.filters {
  margin-bottom: 15px;

  @include break-max($tab){
    margin-bottom: 35px;
  }
  @include break-max($mob){
    margin-bottom: 0;
  }

  &__toggle {
    display: none;
    position: absolute;
    right: 15px;
    padding-top: 5px;
    align-items: center;
    font-weight: 600;
    text-decoration: underline;
    z-index: 5;
    @media (max-width: $mob) {
      display: flex;
      padding-top: 8px;
    }

    &-icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    position: relative;
    background: #fff;
    padding: 10px 30px 10px 60px;

    @include break-max($tab){
      padding: 10px 30px 10px 5px;
    }

    @media (max-width: $mob) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1040;
      flex-direction: column;
      padding: 0 0 60px 0;
      height: 100%;

      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        background: $gray-background-color;
        z-index: -1;
      }

      &.active {
        display: block;
      }
    }
  }

  &__header {
    @media (max-width: $mob) {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      background: $gray-background-color;
    }

    .filters__button--close,
    .filters__button--clear {
      display: none !important;

      @media (max-width: $mob) {
        display: block !important;
        font-size: 16px !important;
        margin-right: 0 !important;
        &:before {
          display: none !important;
        }
      }
    }

    &-title {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 30px;
      font-family: 'Raph Lanok', sans-serif;

      @include break-max($tab){
        display: none;
      }

      @media (max-width: $mob) {
        display: block;
        position: static;
        left: auto;
        top: auto;
        transform: none;
        font-size: 16px;
        font-family: 'Mulish', sans-serif;
        font-weight: 600;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: $mob) {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto;
      max-height: 80%;
      padding: 10px 20px;
    }

    .filters__button--clear {
      margin-left: auto;

      @media (max-width: $mob) {
        display: none !important;
      }
    }

    .filters__button--apply-mob {
      display: none;
      @media (max-width: $mob) {
        display: inline-flex;
        position: absolute;
        bottom: 20px;
        &:after {
          background-image: url(../../../images/ics/filter-white.svg);
        }
      }
    }
  }

  &__select {
    position: relative;
    background: $white-color;
    min-width: 130px;
    font-weight: 400;
    font-size: 14px;
    padding: 0 15px;

    @media (max-width: $mob) {
      display: none;
    }

    &.active {
      background: $secondary-color;
      font-weight: 600;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;

      .filters__select-item{
        .filters__button--any{
          margin-bottom: 5px;
        }
      }

      .filters__field {
        max-width: 100px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      margin-top: 20px;

      @media (max-width: $mob) {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    padding: 0 4px;

    &.in{
      z-index: 1040;
    }

    @media (max-width: $mob) {
      width: 100%;
      padding: 20px 0;
      border-bottom: 2px solid $border-color;

      &:last-child {
        border-bottom: none;
      }

      &.filters__item-multiple{
        border-bottom: none;
      }
    }

    .show-on-mob {
      display: none;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 15px;
      color: $black-color;
      padding: 0;

      @include break-max($mob){
        display: block;
      }
    }

    .app-date{
      .filters__select-dropdown {
        @media (max-width: $tab) {
          left: 4px;
          right: auto;
          transform: translateX(0);
        }
      }
    }
  }

  &__item-multiple{
    &.has_1-column,
    &.has_2-column{
      position: relative;
    }
    &.has_3-column,
    &.has_more-column{
      position: static;
    }
  }

  &__select-dropdown {
    position: absolute;
    background: $gray-background-color;
    padding: 15px;
    min-width: 300px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1040;

    @media (max-width: $mob) {
      position: static;
      left: auto;
      transform: none;
      display: block !important;
      background: none;
      width: 100%;
      padding: 0;
    }

    .irs-from, .irs-to, .irs-single {
      display: none;
    }
  }

  &__label {
    display: none;
  }

  &__field {
    text-align: center;
  }

  &__dropdown {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    top: 83%;
    width: 100%;
    left: 0;
    transform: none;
    background: #ffffff;
    z-index: -1;
    opacity: 0;
    transition: .3s ease;

    @media (max-width: $mob) {
      display: block;
      position: relative;
      top: auto;
      left: auto;
      z-index: 1040;
      opacity: 1;
    }

    &.show {
      display: flex;
      opacity: 1;
      z-index: 1040;
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 25px 25px 0;

      @include break-max($mob){
        padding: 0;
      }
    }

    &-item {
      padding: 5px 20px 20px;
      border-left: 2px solid $border-color;

      &:first-child {
        border-left: none;
      }

      &:nth-child(5n+5){
        border-left: none;
      }

      @include break-max(1200px){
        flex: 0 0 33.33%;
        max-width: 33.33%;

        &:nth-child(5n+5){
          border-left: 2px solid $border-color;
        }

        &:nth-child(4n+4){
          border-left: none;
        }
      }

      @include break-max($mob){
        padding: 0 0 20px 0;
        margin-bottom: 20px;
        flex: 0 0 100%;
        max-width: 100%;
        border-left: none;
        border-bottom: 2px solid $border-color;

        &:nth-child(5n+5){
          border-left: none;
        }

        &:last-child{
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }


      .filters__label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: $black-color;
        padding: 0;
      }

      .filters__checkbox-item {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 10px;

        @include break-max($tab){
          margin-bottom: 14px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        input {
          margin-right: 10px;

          &:checked {
            & ~ .filters__checkbox-label {
              color: $black-color;
            }
          }
        }

        .filters__checkbox-label {
          font-size: 14px;
          font-weight: 300;
          color: $gray-font-color;
          transition: .3s ease;
        }
      }

      select.dr-input{
        width: 100%;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 25px 30px;

      @media (max-width: $mob) {
        display: none;
      }
    }

    &.has_1-column{
      top: 100%;
      width: 400px;
      min-width: 300px;
      left: 50%;
      transform: translateX(-50%);

      .filters__dropdown-item.col-3{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &.has_2-column{
      top: 100%;
      width: 630px;
      min-width: 300px;
      left: 50%;
      transform: translateX(-50%);

      .filters__dropdown-item.col-3{
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &.has_3-column{
      width: 100%;

      .filters__dropdown-item.col-3{
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }

    &.has_1-column,
    &.has_2-column,
    &.has_3-column{
      @include break-max($mob){
        top: auto;
        left: auto;
        width: 100%;
        min-width: unset;
        transform: none;

        .filters__dropdown-item.col-3{
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  &__subcategory {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 25px;
    background: $white-color;
    border-top: 1px solid $border-color;

    &-item {
      color: $gray-font-color;
      font-weight: 300;
      font-size: 14px;
      padding: 5px 15px;
      @include transition;

      &:hover {
        background: $gray-background-color;
        color: $black-color;
      }

      &.active {
        background: $gray-background-color;
        color: $black-color;
        font-weight: 600;
      }
    }
  }

  .filters__button--more {
    position: relative;
    border: 1px solid $border-color;
    height: 38px;
    font-weight: 400;
    background: $white;

    &:before {
      background-image: url(../../../images/ics/filter.svg);
    }

    &.active {
      z-index: 1040;
    }

    @media (max-width: $mob) {
      display: none;
    }
  }

  .filters__button--apply {
    width: auto;
    padding: 0;
    border: none;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $base-color;
    margin-left: 50px;
    cursor: pointer;
  }

  .filters__button--close {
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    margin-right: auto;
    cursor: pointer;
    text-decoration: underline;
  }

  .filters__button--any {
    font-size: 14px;
    font-weight: 300;
    color: $gray-font-color;
    cursor: pointer;
  }

  .filters__button--clear {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #880000;
    text-decoration: underline;
    cursor: pointer;

    &:before {
      display: flex;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url(../../../images/ics/clear-filters.svg);
    }
  }

  &__component-datepicker {
    min-width: 300px;
    margin: 0 auto;

    @include break-max($mob){
      width: 100%;
    }

    .datepicker {
      width: 100%;

      table {
        width: 100%;
        th.next, th.prev {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 0;
          border-radius: 50px;
          padding: 0;
          width: 40px;
          height: 40px;
          background: $white-color;
          &:before {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            background: url(../../../images/ics/arrow-right.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        th.prev:before {
          transform: scale(-1);
        }
        .datepicker-switch {
          background: none;
          font-weight: 600;
          font-size: 16px;
          color: $black-color;
          &:hover {
            text-decoration: underline;
          }
        }
        th.dow {
          font-weight: 600;
          font-size: 14px;
          padding: 20px 0 0 0;
          color: $gray-font-color;
        }
        tbody {
          td.day {
            width: 40px;
            line-height: 39px;
            border-radius: 50px;
            text-align: center;
            height: auto;
            padding: 0;
            text-shadow: none;
            @include transition;
            &.old {
              cursor: not-allowed;
            }
            &.today {
              background: $secondary-color;
            }
            &:hover:not(.disabled), &.active {
              background: $base-color;
              color: $white-color;
            }
            &.disabled:hover {
              background: none;
            }
          }
        }
      }
    }
  }


}