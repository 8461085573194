.page{


  &.page__sport{
    .section__advantage{
      background: $gray-background-color;
    }

    .section__top-activities .ui-section-title{
      color: $white-color;
    }

    .section__top-activities .top-activities-block ul{
      .top-activities__item{

        .top-activities__link{

          .top-activities__text{
            background: #2d2d2d;
            color: $white-color;
          }
        }
      }
    }
  }

  &.page__education{
    .map-section{
      &:before{
        background: $secondary-color;
      }
    }
  }


  &.page__sport{
    .map-section{
      &:before{
        background: #2d2d2d;
      }

      .custom-informer .small-title{
        color: $base-color;
      }
    }
  }
}