.cta-block{
  padding: 0 0 40px 0;
  background: #141414;
  position: relative;

  @include break-max($mob){
    background: #fff;overflow: hidden;
  }

  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: #fff;

    @include break-max($mob){
      left: 50%;
      bottom: 100px;
      height: 100%;
      background: #141414;
      min-width: 900px;
      min-height: 1000px;
      border-radius: 0 0 50% 50%;
      transform: translateX(-50%);
    }
  }

  &__body{
    padding: 40px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 740px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;

    @include break-max($mob){
      max-width: 400px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 0;
    }
  }

  &__header{

    .ui-section-title{
      color: $white-color;
      margin-bottom: 5px;

      @media (max-width: $mob) {
        font-size: 28px;
      }
    }

    .ui-subtitle{
      font-size: 16px;
      line-height: 1.4;
      color: $white-color;
      margin-bottom: 20px;
      letter-spacing: normal;
      font-weight: normal;
    }

    .btn-bar{
      display: flex;

      @media (max-width: $mob) {
        flex-direction: column;
        align-items: center;
      }

      .dr-btn {
        margin-right: 25px;

        @media (max-width: $mob) {
          margin-bottom: 20px;
          margin-right: 0;
        }
      }

      .dr-link {
        text-decoration: underline;
      }
      .dr-btn--icon-right{
        &:after{
          background: url("../../images/ics/arrow-right.svg") no-repeat center;
          background-size: 15px;
        }
      }
    }

    @include break-max($mob){
      margin-bottom: 20px;
    }
  }

  &__footer{
    @include break-max($mob){
      width: 100%;
    }

    .cta-block__koteyko{
      height: 415px;
      width: 100%;
      max-width: 270px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: url("../../images/cta/cat-wink.svg") no-repeat;
      background-position: bottom right 2%;
      background-size: 198px;

      @include break-min($mob + 1){
        align-items: center;
        justify-content: flex-start;
        min-width: 320px;
        max-width: 100%;
        width: 320px;
        height: 294px;
        margin-bottom: -70px;
        padding-top: 90px;
        padding-right: 180px;
        background: url("../../images/cta/cat-arrow.svg") no-repeat;
        background-size: 241px;
        background-position: top right;
      }
    }

    .ui-cursive{
      color: $base-color;
      position: relative;
      margin-bottom: 10px;

      &:after{
        content: '';
        display: inline-block;
        position: relative;
        height: 100px;
        width: 70px;
        background: url("../../images/cta/arrow-CTA.svg")no-repeat center;
        left: -40px;
        bottom: -14px;
      }

      @include break-min($mob + 1){
        left: -20px;
        margin-bottom: 0;

        &:after{
          display: none;
        }
      }
    }
  }

  &.cta-block-v2{
    &:after{
      height: 40px;
      background: #fff;

      @include break-max($mob){
        left: 50%;
        bottom: 100px;
        height: 100%;
        background: #141414;
        min-width: 900px;
        min-height: 1000px;
        border-radius: 0 0 50% 50%;
        transform: translateX(-50%);
      }
    }

    .cta-block__body{
      max-width: 1000px;

      @include break-max(992px){
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 600px;
        text-align: center;
      }
    }

    .cta-block__header{
      @include break-max(992px){
        margin-bottom: 30px;
      }
    }

    .cta-block__footer{
      @include break-max(992px){
        width: 100%;
      }

      .cta-block__koteyko{
        height: 270px;
        width: 100%;
        padding: 0;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: url("../../images/cta/cat-mouse-house.svg") no-repeat;
        background-position: bottom right 0px;
        background-size: 298px;

        @include break-min(992px){
          align-items: center;
          justify-content: flex-end;
          min-width: 472px;
          max-width: 100%;
          width: 472px;
          height: 316px;
          margin-bottom: -92px;
          padding: 0px 0 210px 0;
          background: url("../../images/cta/cat-mouse-house.svg") no-repeat;
          background-size: 472px;
          background-position: top right;
        }
      }

      .ui-cursive{
        position: relative;
        margin-bottom: 10px;
        left: 0;
        right: 0;

        &:after{
         display: none;
        }

        @include break-min($mob + 1){

        }
      }
    }
  }
}